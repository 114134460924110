import styled from "@emotion/styled";
import { CircularProgress, IconButton, Menu, MenuItem, Sheet, Tooltip } from "@mui/joy";
import { useProject } from "context/ProjectContext";
import { getProjectSessionsApi } from "network/api/project/dashboard";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { formatDateTime, logErr } from "utils/helpers";
import { DiLinux } from "react-icons/di";
import { FaDesktop, FaEdit, FaEye, FaSlackHash } from "react-icons/fa";
import { AiFillAndroid, AiFillWindows } from "react-icons/ai";
import { SiChatbot, SiZapier } from "react-icons/si";
import { BsDiscord } from "react-icons/bs";
import { ImMobile } from "react-icons/im";
import { useAuth } from "context/AuthContext";
import { IoFilter } from "react-icons/io5";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function Table() {
  const { token } = useAuth();
  const { projectUid } = useProject();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([{ id: "", model: "", fine_tuned_model: "", created_at: "", status: "" }]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const menuOpen = Boolean(anchorEl);
  const [status, setStatus] = useState<undefined | string>();

  // FUNCTIONS
  const getIntegrationName = (id: number) => {
    let integraion = "";
    if (id === 1) {
      integraion = "Discord Bot";
    } else if (id === 2) {
      integraion = "Slack Bot";
    } else if (id === 3) {
      integraion = "ChatBot";
    } else if (id === 4) {
      integraion = "Zapier";
    }

    return integraion;
  };
  const getDeiveIcon = (type: string) => {
    return <>{type === "mobile" ? <ImMobile /> : type === "desktop" ? <FaDesktop /> : <></>}</>;
  };
  const getPlatformIcon = (type: string) => {
    return <>{type === "linux" ? <DiLinux /> : type === "windows" ? <AiFillWindows /> : type === "mac" ? <></> : type === "android" ? <AiFillAndroid /> : type === "ios" ? <></> : <></>}</>;
  };
  const getIntegrationIcon = (type: string) => {
    return <>{type === "ChatBot" ? <SiChatbot /> : type === "Discord" ? <BsDiscord /> : type === "Slack" ? <FaSlackHash /> : type === "Zapier" ? <SiZapier /> : <></>}</>;
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (!projectUid || !token) return;

    const getSessions = async () => {
      try {
        setLoading(true);
        const res = await getProjectSessionsApi({ raw: { project_uid: projectUid, status: status }, token });
        setData(res?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        logErr("Err", err);
      }
    };

    getSessions();
  }, [projectUid, token, status]);

  const COLS: {
    name: string;
    wrap: boolean;
    selector: (d: any) => any;
  }[] = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row?.id,
        wrap: true,
        width: "10%",
        bold: true,
      },
      {
        name: "Device",
        selector: (row) => (
          <div className="flex items-center gap-2 capitalize" style={{ whiteSpace: "nowrap" }}>
            {row?.device_type ? row?.device_type : "N/A"} <>{getDeiveIcon(row?.device_type)}</>{" "}
          </div>
        ),
        wrap: true,
        bold: true,
      },
      {
        name: "Platform",
        width: "10%",
        selector: (row) => (
          <div className="flex items-center gap-2">
            {row?.platform ? row?.platform : "N/A"} <>{getPlatformIcon(row?.platform)}</>{" "}
          </div>
        ),
        wrap: true,
        bold: true,
      },
      {
        name: "IP",
        selector: (row) => <div style={{ whiteSpace: "nowrap" }}>{row?.ip}</div>,
        wrap: true,
        bold: true,
      },
      {
        name: "Integration",
        selector: (row) => (
          <div className="flex items-center gap-2" style={{ whiteSpace: "nowrap" }}>
            {getIntegrationName(row?.integration_id)} <>{getIntegrationIcon(getIntegrationName(row?.integration_id))}</>{" "}
          </div>
        ),
        wrap: true,
        bold: true,
      },
      {
        name: "Status",
        selector: (row) => <div className="capitalize">{row?.status}</div>,
        width: "10%",
        wrap: true,
        bold: true,
      },
      {
        name: "Created date",
        wrap: true,
        selector: (i) => formatDateTime(i.createdAt),
      },
      {
        name: "Actions",
        wrap: true,
        width: "8%",
        selector: (i) => (
          <Tooltip title="Session Details" variant="solid">
            <Link to={`session/${i.session_uid}`}>
              <IconButton variant="outlined">
                <FaEye />
              </IconButton>
            </Link>
          </Tooltip>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <div className="flex justify-end -mt-10 mb-5">
        <div className="flex items-center gap-2 cursor-pointer text-black/70" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <IoFilter />
          <span>Filter</span>
          <RiArrowDownSLine className={`${menuOpen ? "rotate-180 duration-200" : ""}`} />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => setAnchorEl(null)}
          sx={{
            minWidth: 120,
          }}
        >
          <MenuItem
            sx={{ ":focus-visible": { outline: "none" } }}
            color="neutral"
            onClick={() => {
              setStatus(undefined);
              setAnchorEl(null);
            }}
          >
            All
          </MenuItem>
          <MenuItem
            sx={{ ":focus-visible": { outline: "none" } }}
            color="neutral"
            onClick={() => {
              setStatus("open");
              setAnchorEl(null);
            }}
          >
            Open
          </MenuItem>
          <MenuItem
            sx={{ ":focus-visible": { outline: "none" } }}
            color="neutral"
            onClick={() => {
              setStatus("closed");
              setAnchorEl(null);
            }}
          >
            Closed
          </MenuItem>
        </Menu>
      </div>
      <Sheet sx={{ borderRadius: 12 }}>
        {loading ? (
          <div className="flex h-20 items-center justify-center shadow-md rounded-xl">
            <CircularProgress size="md" />
          </div>
        ) : (
          <Container className="shadow-md rounded-xl overflow-hidden">
            <DataTable columns={COLS} data={data} pagination paginationRowsPerPageOptions={[10, 50, 100]} paginationPerPage={10} />
          </Container>
        )}
      </Sheet>
    </div>
  );
}

const Container = styled.div`
  .lointe {
    font-weight: 700;
    font-size: 15px;
  }
`;
