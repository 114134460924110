import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginApiResD } from "types/apiResponse";
import { UserProfileD } from "types/user";
import { STORAGE_KEYS } from "utils/constants/app";

declare const window: any;

type AuthContextTypeD = {
  token: string;
  user: any;
  onLoginSuccess: (d: { data: LoginApiResD & UserProfileD; persist: boolean }) => any;
  logout: () => void;
  metaInfo: any;
  clearMetaInfo: () => void;
};

const AuthContext = createContext<AuthContextTypeD>({} as AuthContextTypeD);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState<UserProfileD>({} as UserProfileD);
  const [userId, setUserId] = useState("");
  const [metaInfo, setMetaInfo] = useState<any>(null);
  const currentPath = useLocation();

  // FUNCTIONS
  const onLoginSuccess = ({ data, persist = true }: { persist: boolean; data: LoginApiResD & UserProfileD }) => {
    setUser(data);
    setToken(data.token);
  };

  const logout = async () => {
    setToken("");
    Cookies.remove("token", { domain: "yourgpt.ai" });
    localStorage.removeItem(STORAGE_KEYS.token);
    localStorage.removeItem(STORAGE_KEYS.user);
    window.location.href = "https://app.yourgpt.ai/login";
  };

  const clearMetaInfo = () => {
    setMetaInfo(null);
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (currentPath?.search) {
      const params = decodeURIComponent(currentPath?.search);
      const q = params?.replace(/\?/, "")?.split("&");
      const meta = {
        redirect: q[0]?.split("=")[1],
        purpose: q[1]?.split("=")[1],
        url: q[2]?.split("=")[1],
      };
      setMetaInfo(meta);
    }
  }, [currentPath?.search]);

  useEffect(() => {
    // TESTING
    if (typeof process.env.REACT_APP_DUMMY_TOKEN !== "undefined" && process.env.REACT_APP_DUMMY_TOKEN) {
      setToken(process.env.REACT_APP_DUMMY_TOKEN);
      return;
    }
    if (Cookies.get(STORAGE_KEYS.token)) {
      setToken(Cookies.get(STORAGE_KEYS.token) || "");
      setUserId(Cookies.get(STORAGE_KEYS.userId)!);
    } else {
      const nonAuths = ["forgot-password", "login", "reset-password", "create-account", "verifyEmail"];

      let isDash = !nonAuths.some((i) => pathname.includes(i));

      if (isDash) {
        Cookies.set(STORAGE_KEYS.prodURL, "https://tuneup.yourgpt.ai", {
          domain: "yourgpt.ai",
          expires: 365,
        });
        window.location.href = "https://app.yourgpt.ai/login";
      }
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (user.id && typeof window !== "undefined") {
      window?.clarity("identify", user.id);
    }
  }, [user]);

  useEffect(() => {
    if (!userId) return;

    const clarity = window.clarity;
    if (clarity) {
      clarity("set", "user", userId);
    }
  }, [userId]);

  return (
    <AuthContext.Provider
      value={{
        token,
        user: {},
        onLoginSuccess,
        logout,
        metaInfo,
        clearMetaInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
