import { Chip, CircularProgress, Input, Menu } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "store";
import { fetchProjectsThunk } from "store/projectsSlice";
import { ProjectItemD } from "types/project";
import { useDebounce } from "use-debounce";
import { getProjectsApi } from "network/api/project";
import { ApiResType } from "types/enum";
import { logErr } from "utils/helpers";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@mui/base";

export default function ProjectsDropdown() {
  const projectsSlice = useAppSelector((s) => s.projects);
  const dispatch = useAppDispatch();
  const { token } = useAuth();
  const { activeProject } = useProject();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  //SEARCHED DATA
  const [projects, setProjects] = useState<ProjectItemD[]>([]);
  const [search, setSearch] = useState("");
  const [delaySearch] = useDebounce(search, 300);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const fetchP = async () => {
      try {
        setSearching(true);

        const res = await getProjectsApi({ token, search: delaySearch, limit: 10, page: 1 });
        setSearching(false);
        if (res.type === ApiResType.SUCCESS) {
          setProjects(res.data);
        }
      } catch (err) {
        setSearching(false);
        logErr("Err", err);
      }
    };

    if (token && delaySearch) {
      fetchP();
    }
  }, [delaySearch, token]);

  useEffect(() => {
    if (projectsSlice.list.length === 0 && !projectsSlice.loading && token) {
      dispatch(fetchProjectsThunk({ token, search: "" }));
    }
  }, [token, dispatch]);

  let showList = projectsSlice.list || [];

  if (search) {
    showList = projects;
  } else {
    showList = projectsSlice.list;
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div className="absolute h-full">
          <div id="projects-menu" onClick={(e) => setAnchorEl(e.currentTarget)} className={` ${open ? "bg-white/20 " : ""} h-full px-2  flex gap-4 items-center cursor-pointer`}>
            <div>
              <div className="text-white/60 text-sm">Project</div>
              <div className="text-white font-semibold whitespace-nowrap max-w-[120px] overflow-hidden text-ellipsis ">{activeProject?.loading ? <small> Loading... </small> : activeProject?.name}</div>
            </div>

            <FiChevronDown className="text-white" />
          </div>

          <Menu
            id="projects-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            sx={{ width: 300, maxHeight: 300, overflow: "auto" }}
            placement="bottom-start"
          >
            <div className="px-4 py-3">
              <div className="font-semibold text-sm mb-1 text-black/40">Your Projects</div>
              <Input onChange={(e) => setSearch(e.target.value)} value={search} type="search" size="sm" placeholder="Search your projects..." />
            </div>

            {searching && (
              <div className="py-4 flex justify-center">
                <CircularProgress size="sm" />
              </div>
            )}

            <div className="divide-y divide-gray-100">
              <div className=" bg-blue-50 flex px-4 py-2 items-center gap-2 justify-between">
                {activeProject?.loading ? (
                  <CircularProgress size="sm" />
                ) : (
                  <div className={`flex flex-col`}>
                    <div className="font-semibold">{activeProject?.name}</div>
                    {/* <div className="text-xs text-gray-500"> {activeProject?.project_uid}</div> */}
                  </div>
                )}
                <Chip variant="solid" color="primary" size="sm">
                  Active
                </Chip>
              </div>

              {showList
                .filter((i) => i.project_uid !== activeProject?.project_uid)
                .map((i) => {
                  return (
                    <Link
                      to={`/projects/${i.project_uid}`}
                      className={`px-4 py-2 flex flex-col hover:bg-gray-50 `}
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                    >
                      <div>{i?.name}</div>

                      {/* <div className="text-xs text-gray-500"> {i.project_uid}</div> */}
                    </Link>
                  );
                })}
            </div>
          </Menu>
        </div>
      </ClickAwayListener>
    </>
  );
}
