export enum ApiResType {
  SUCCESS = "RXSUCCESS",
  ERROR = "RXERROR",
}

export enum Purpose {
  chatbot = 'chatbot',
  qaExpert = 'qa_expert',
  fineTuning = 'fine_tuning',
  contentWriter = 'content_writer',
  personalAssistant = 'personal_assistant',
  customerSupport = 'customer_support_expert'
}

