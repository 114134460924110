import { Autocomplete } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { getFilesApi } from "network/api/project/file";
import { useCallback, useEffect, useState } from "react";
import { ProjectFileItemD } from "types/project";
import { logErr } from "utils/helpers";

export default function FileSelect({
  value,
  onLoadStart,
  onLoad,
  onChange,
  autoSet = true,
  name,
}: {
  onLoadStart?: () => any;
  onLoad?: (d: ProjectFileItemD) => any;
  value: ProjectFileItemD | null;
  onChange: (d: ProjectFileItemD) => any;
  autoSet?: boolean;
  name?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(value);
  const [files, setFiles] = useState<ProjectFileItemD[]>([]);

  const [search, setSearch] = useState("");

  const { token } = useAuth();

  const { projectUid } = useProject();

  const fetchFile = useCallback(async () => {
    let active = true;

    if (!token || !projectUid) {
      return;
    }

    try {
      setLoading(true);

      setLoading(false);

      if (active) {
        const res = await getFilesApi({
          limit: 10,
          page: 1,
          project_uid: projectUid,
          search,
          token,
        });

        if (res.type === "RXSUCCESS") {
          if (autoSet) {
            setFile(res.data[0]);
          }
          setFiles(res.data);
        }
      }
    } catch (err) {
      setLoading(false);
      logErr("Err", err);
    }
    return () => {
      active = false;
    };
  }, [search, token, projectUid]);

  useEffect(() => {
    fetchFile();
  }, [fetchFile]);

  useEffect(() => {
    setFile(value);
  }, [value]);

  useEffect(() => {
    if (loading && onLoadStart) {
      onLoadStart();
    }
  }, [loading]);

  useEffect(() => {
    if (file?.id) {
      onChange(file);
    }
  }, [file]);

  return (
    <div>
      <Autocomplete
        size="sm"
        multiple={false}
        name={name}
        options={files}
        placeholder="Select file"
        value={file}
        onChange={(_, val: ProjectFileItemD | null) => {
          if (val) {
            setFile(val);
          }
        }}
        loading={loading}
        // onInputChange={onOrgChange}
        getOptionLabel={(option: ProjectFileItemD) => {
          return option.filename + " (" + option.id + ")";
        }}
        // renderOption={(props, option) => {
        //   return (
        //     <AutocompleteOption {...props}>
        //       <div className="flex flex-col gap-2 py-2">
        //         <div>{option.id}</div>
        //       </div>
        //     </AutocompleteOption>
        //   );
        // }}
      />
    </div>
  );
}
