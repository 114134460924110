export enum PLAN_KEY {
  tuneup_professional_yearly = "tuneup_professional_yearly",
}

export type ActivePlanDetailD = {
  usage: { members: { limit: string; usage: string } };
  plan: string;
  trail_plan: any;
  subscription_id: number;
  plan_id: string;
  next_billing_cycle: NextBillingCycle;
  paid_invoice: PaidInvoice[];
  status: null | SubscriptionStatus;
  subscriptionData: SubscriptionData;
  pastDuePlanDetail: any;
};

export type NextBillingCycle = {
  id: number;
  invoice_id: any;
  amount_due: number;
  amount_paid: number;
  amount_remaining: string;
  created: number;
  period_start: number;
  period_end: number;
  billing_reason: string;
  currency: string;
  customer_id: string;
  transaction_id: any;
  customer_email: string;
  customer_name: string;
  subscription_id: string;
  status: string;
  payment_intent: any;
  total: number;
  subtotal: number;
  invoice_pdf: any;
  hosted_invoice_url: any;
  app_id: number;
  organization_id: number;
  due_date: any;
  paid: boolean;
  createdAt: string;
  updatedAt: string;
};

export type PaidInvoice = {
  id: number;
  invoice_id: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: string;
  created: number;
  period_start: number;
  period_end: number;
  billing_reason: string;
  currency: string;
  customer_id: string;
  transaction_id: string;
  customer_email: string;
  customer_name: string;
  subscription_id: string;
  status: string;
  payment_intent: string;
  total: number;
  subtotal: number;
  invoice_pdf: string;
  hosted_invoice_url: string;
  app_id: number;
  organization_id: number;
  due_date: any;
  paid: boolean;
  createdAt: string;
  updatedAt: string;
};

export type SubscriptionData = {
  id: number;
  subscription_id: string;
  app_id: number;
  organization_id: number;
  canceled_at: any;
  cancel_at_period_end: boolean;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer_id: string;
  user_id: string;
  project_id: any;
  plan_id: number;
  status: string;
  stripe_plan_id: string;
  plan_name: string;
  subscription_benefits: string;
  cancel_at: any;
  createdAt: string;
  updatedAt: string;
  Invoices: Invoice[];
};

export type Invoice = {
  id: number;
  invoice_id?: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: string;
  created: number;
  period_start: number;
  period_end: number;
  billing_reason: string;
  currency: string;
  customer_id: string;
  transaction_id?: string;
  customer_email: string;
  customer_name: string;
  subscription_id: string;
  status: string;
  payment_intent?: string;
  total: number;
  subtotal: number;
  invoice_pdf?: string;
  hosted_invoice_url?: string;
  app_id: number;
  organization_id: number;
  due_date: any;
  paid: boolean;
  createdAt: string;
  updatedAt: string;
};

export enum SubscriptionStatus {
  active = "active",
  cancel = "cancel",
  incomplete = "incomplete",
  paused = "paused",
  trialing = "trialing",
  incomplete_expired = "incomplete_expired",
  complete = "complete",
  expired = "expired",
}
