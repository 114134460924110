import { Table, Typography } from "@mui/joy";
import React from "react";
import { FineTuneEventD } from "types/fineTune";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Timestamp",
    selector: (row: FineTuneEventD) => row.created_at,
  },
  {
    name: "Message",
    selector: (row: FineTuneEventD) => row.message,
    wrap: true,
  },
];

export default function Events({ events }: { events: FineTuneEventD[] }) {
  return (
    <div className="mb-8 bg-white p-4 shadow-sm">
      <div>
        <Typography level="h4">Events</Typography>
        <Typography level="body-md">{events?.length} results</Typography>
      </div>

      <div>
        <DataTable columns={columns} data={events} pagination paginationRowsPerPageOptions={[5, 10, 100]} paginationPerPage={5} />
      </div>
    </div>
  );
}
