import { post } from "network";

export const getFinetuneDatasetApi = async ({ token, ...raw }: { token: string; search?: string; category?: string; limit: number; page: number }) => {
  return post({
    route: "/api/v1/basictuning/getFinetuneDataset",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};

export const createDatasetWithAIApi = async ({ token, ...raw }: { token: string; purpose: string; project_uid: string; model: string }) => {
  return post({
    route: "/api/v1/basictuning/createDatasetWithAI",
    data: JSON.stringify(raw),
    config: {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  });
};
