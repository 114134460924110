import { Alert, Button } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { cancelSubscriptionApi } from "network/api/project/subscription";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaDownload } from "react-icons/fa";
import { ApiResType } from "types/enum";
import { SubscriptionStatus } from "types/subscription";
import { formatDateTime } from "utils/helpers";

export default function SubscriptionInfo() {
  const { token } = useAuth();
  const { activePlanDetails } = useProject();
  const [cancelling, setCancelling] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const isCanceled = activePlanDetails?.subscriptionData?.canceled_at || activePlanDetails?.status === SubscriptionStatus.cancel;

  const onCancelSub = async () => {
    try {
      if (!token || !activePlanDetails?.subscription_id) {
        return;
      }

      setCancelling(true);
      const res = await cancelSubscriptionApi({ token, subscription_id: activePlanDetails.subscription_id?.toString() });
      setCancelling(false);
      setCancelOpen(false);

      if (res.type === ApiResType.SUCCESS) {
        toast.success("Your subscription has been cancelled");
      }

      if (res.type === ApiResType.ERROR) {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error("Something went wrong, try again");
      setCancelling(false);
      console.log("Err", err);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto pt-5 border-t text-black/70">
      <h3 className="text-lg font-semibold text-black mb-3">Subscription Info</h3>
      {isCanceled && (
        <Alert color="danger" size="md" variant="soft" className="mb-3 w-fitt">
          Your subscription was canceled on {formatDateTime(activePlanDetails?.subscriptionData?.canceled_at)}
        </Alert>
      )}
      <div>Start Date: {formatDateTime(activePlanDetails?.next_billing_cycle?.period_start)}</div>
      <div>End Date: {formatDateTime(activePlanDetails?.next_billing_cycle?.period_end)}</div>
      <div className="flex gap-2 mt-3">
        {activePlanDetails?.paid_invoice[0]?.invoice_pdf && (
          <a href={activePlanDetails?.paid_invoice[0]?.invoice_pdf}>
            <Button variant="soft">
              <FaDownload className="mr-2" />
              Invoice
            </Button>
          </a>
        )}
        {!isCanceled && (
          <Button variant="plain" color="danger" onClick={() => setCancelOpen(true)}>
            Cancel Subscription
          </Button>
        )}
      </div>
      <ConfirmModal title="Cancel your subscription" loading={cancelling} onConfirm={onCancelSub} onClose={() => setCancelOpen(false)} open={cancelOpen} danger />
    </div>
  );
}
