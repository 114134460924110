import { Modal, Sheet } from "@mui/joy";
import OpenAI from "./OpenAI";
import Zapier from "./Zapier";

const MODAL_HEIGHT = 450;

export default function IntegrationModal({ open, onClose, type }: { open: boolean; onClose: () => void; type: string }) {
  const Content = () => {
    switch (type) {
      case "openai":
        return <OpenAI />;
      case "zapier":
        return <Zapier />;
    }
  };

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 300,
          borderRadius: "sm",
          maxWidth: "90%",
          width: 700,
          minHeight: MODAL_HEIGHT,
          position: "relative",
          zIndex: 10,
          overflowY: "auto",
        }}
      >
        <div className="p-5 overflow-y-auto max-h-[90vh]" style={{ minHeight: MODAL_HEIGHT }}>
          {Content()}
        </div>
      </Sheet>
    </Modal>
  );
}
