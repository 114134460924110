import SettingLayout from "components/layouts/SettingLayout";
import { appContent } from "components/variants/app";
import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { tv } from "tailwind-variants";

export default function ProjectSettings() {
  const { projectUid } = useParams();
  const links = useMemo(
    () => [
      // {
      //   label: "General",
      //   link: `/projects/${projectUid}/settings/general`,
      // },
      // {
      //   label: "Keys & Domains",
      //   link: `/projects/${projectUid}/settings/keys`,
      // },
      {
        label: "Members",
        link: `/projects/${projectUid}/settings/members`,
      },
      {
        label: "OpenAI Key",
        link: `/projects/${projectUid}/settings/openAiKey`,
      },
    ],
    [projectUid]
  );

  return (
    <div className={appContent({ place: "console" })}>
      <SettingLayout links={links}>
        <Outlet />
      </SettingLayout>
    </div>
  );
}

export const sectionsV = tv({
  base: "flex flex-col gap-8",
});
export const sectionV = tv({
  base: "",
});
export const fieldV = tv({
  base: "",
});
export const labelV = tv({
  base: "text-sm font-semibold opacity-60 mb-1",
});

//OUTERS
export const sectionH = tv({
  base: "text-base font-semibold opacity-90 mb-2 ",
});
