import Pricing from "components/Pricing";
import { appContent } from "components/variants/app";

export default function Upgrade() {
  return (
    <div className={appContent()}>
      <Pricing />
    </div>
  );
}
