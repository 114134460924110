import { Alert, Button, CircularProgress, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, Sheet, Table, Typography } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import TablePagination from "components/TablePagination";
import { appContent } from "components/variants/app";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { createProjectSitemapApi, deleteProjectSiteMapApi, getProjectSiteMapApi } from "network/api/project/chatbot";
import { FormEvent, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import styled from "styled-components";
import { log, logErr } from "utils/helpers";

const COLS = [
  {
    id: 1,
    label: "ID",
    width: "20%",
  },
  {
    id: 2,
    label: "Sitemap URL",
    width: "80%",
  },
  {
    id: 3,
    label: "Actions",
    width: "20%",
  },
];

export default function Sitemap() {
  const { token } = useAuth();
  const { projectUid } = useProject();
  const [page, setPage] = useState(1);
  const [sitemapArr, setSitemapArr] = useState<{ id: number; site_map_url: string }[]>([]);
  const [errorMsg, setErrorMsg] = useState({} as { type: "create" | "update" | "delete"; message: string });
  const [loading, setLoading] = useState({} as { type: "create" | "read" | "update" | "delete"; bool: boolean });
  const [showForm, setShowForm] = useState({} as { type: "create" | "update"; bool: boolean; url?: string });
  const [updatedUrl, setUpdatedUrl] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  // FUNCTIONS
  const createSitemap = async ({ url }: { url: string }) => {
    try {
      setLoading({ type: "create", bool: true });
      const res = await createProjectSitemapApi({ project_uid: projectUid, site_map_url: url, token });
      if (res?.type === "RXSUCCESS") {
        setSitemapArr([...sitemapArr, res?.data?.site_map_url]);
        setShowForm({ type: "create", bool: false });
      }
      if (res?.type === "RXERROR") {
        setErrorMsg({ type: "create", message: res?.message || "Something went wrong!" });
      }
      setLoading({ type: "create", bool: false });
    } catch (err: any) {
      logErr("Err", err);
      setLoading({ type: "create", bool: false });
      setErrorMsg({ type: "create", message: err?.message || "Something went wrong!" });
    }
  };

  const updateSitemap = async ({ url, prev }: { url: string; prev: string }) => {
    try {
      setLoading({ type: "update", bool: true });
      let res = { type: "RXSUCCESS", message: "no message" };
      log("url", url);
      // const res = await updateProjectSiteMapApi({ sitemap_url: url, token})
      if (res?.type === "RXSUCCESS") {
        setSitemapArr(
          sitemapArr.map((sitemap) => {
            if (sitemap.site_map_url === prev) {
              return { ...sitemap, site_map_url: url };
            } else {
              return sitemap;
            }
          })
        );
        setShowForm({ type: "update", bool: false });
      }
      if (res?.type === "RXERROR") {
        setErrorMsg({ type: "update", message: res?.message || "Something went wrong!" });
      }
      setLoading({ type: "update", bool: false });
    } catch (err: any) {
      logErr("Err", err);
      setLoading({ type: "update", bool: false });
      setErrorMsg({ type: "update", message: err?.message || "Something went wrong!" });
    }
  };

  const deleteSitemap = async () => {
    try {
      setLoading({ type: "delete", bool: true });
      await deleteProjectSiteMapApi({ project_uid: projectUid, id: deleteId, token });
      setSitemapArr((prevArray) => prevArray.filter((sitemap) => sitemap.id?.toString() !== deleteId));
      setDeleteId("");
      setLoading({ type: "delete", bool: false });
    } catch (err: any) {
      logErr("Err", err);
      setLoading({ type: "delete", bool: false });
      setErrorMsg({ type: "delete", message: err?.message || "Something went wrong!" });
    }
  };

  const onSubmitCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const urlInput = e.currentTarget.elements.namedItem("url") as HTMLInputElement;
    const urlValue = urlInput?.value;
    createSitemap({ url: urlValue });
  };

  // const onSubmitUpdate = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const urlInput = e.currentTarget.elements.namedItem("url") as HTMLInputElement;
  //   const urlValue = urlInput?.value;
  //   updateSitemap({url: urlValue})
  // }

  // SIDE EFFECTS
  useEffect(() => {
    if (!projectUid || !token) return;

    const getProjectSitemaps = async () => {
      try {
        setLoading({ type: "read", bool: true });
        const res = await getProjectSiteMapApi({ token, project_uid: projectUid });
        setSitemapArr(res?.data);
        setTotal(res?.data?.length);
        setLoading({ type: "read", bool: false });
      } catch (err) {
        setLoading({ type: "read", bool: false });
        logErr("ERr", err);
      }
    };

    getProjectSitemaps();
  }, [projectUid, token]);

  return (
    <Root
      className={appContent({
        place: "train",
      })}
    >
      {/* HEADER */}
      <div className="flex justify-between">
        <div className="right">
          <Typography level="title-lg">Your Sitemap Urls</Typography>
          <Typography level="body-md">{sitemapArr.length} results</Typography>
        </div>

        <div className="right flex items-center gap-2">
          <Button size="md" startDecorator={<FiPlus />} onClick={() => setShowForm({ type: "create", bool: true })}>
            Add new
          </Button>
        </div>
      </div>

      {/* LISTING */}
      <List>
        <Sheet>
          {loading.type === "read" && loading.bool ? (
            <div className="flex h-20 items-center justify-center">
              <CircularProgress size="md" />
            </div>
          ) : (
            // <DataTable columns={COLS} data={sitemapArr} pagination paginationRowsPerPageOptions={[10, 50, 100]} paginationPerPage={10} />
            <Table>
              <thead>
                <tr>
                  {COLS.map((i) => {
                    return (
                      <th key={i.id} style={{ width: i.width }}>
                        {i.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {sitemapArr.map((i) => {
                  return (
                    <tr key={i.id}>
                      <td>
                        <Typography fontWeight={"lg"}>{i.id}</Typography>
                      </td>
                      <td>
                        <div className="flex flex-col gap-2 items-start">
                          {showForm.type === "update" && showForm.bool && showForm.url === i.site_map_url ? (
                            <>
                              <div className="flex gap-2 w-full">
                                <Input placeholder="New sitemap url" size="sm" className="w-full" name="url" value={updatedUrl} onChange={(e) => setUpdatedUrl(e.target.value)} />
                                <Button type="submit" size="sm" className="whitespace-nowrap" onClick={() => updateSitemap({ url: updatedUrl, prev: i.site_map_url })}>
                                  Update
                                </Button>
                                <Button variant="outlined" color="danger" size="sm" className="whitespace-nowrap" onClick={() => setShowForm({ type: "update", bool: false })}>
                                  Cancel
                                </Button>
                              </div>
                              {errorMsg.type === "update" && (
                                <Alert variant="plain" color="danger">
                                  {errorMsg.message}
                                </Alert>
                              )}
                            </>
                          ) : (
                            <div className="font-semibold">{i.site_map_url}</div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="actions cell flex gap-1">
                          {/* <IconButton variant="outlined" onClick={() => {
                            setShowForm({type: 'update', bool: true, url: i.site_map_url});
                            setUpdatedUrl(i.site_map_url);
                          }}>
                            <FaEdit />
                          </IconButton>    */}
                          <IconButton color="danger" variant="outlined" onClick={() => setDeleteId(i.id.toString())}>
                            <AiFillDelete />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={COLS.length}>
                    <TablePagination {...{ limit, page, total }} onPage={setPage} onLimit={setLimit} />
                  </td>
                </tr>
              </tfoot>
            </Table>
          )}
        </Sheet>
      </List>

      <ConfirmModal danger onClose={() => setDeleteId("")} open={deleteId ? true : false} onConfirm={deleteSitemap} loading={loading.type === "delete" && loading.bool} confirmTitle="Yes delete" title="Delete this sitemap url" />

      <Modal
        aria-labelledby="close-modal-title"
        open={showForm.type === "create" && showForm.bool}
        onClose={() => setShowForm({ type: "create", bool: false })}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 300,
            borderRadius: "md",
            p: 3,
            maxWidth: "90%",
            width: 600,
          }}
        >
          <ModalClose variant="outlined" />

          <div>
            <div className="mb-4">
              <Typography level="title-lg">Add new sitemap url</Typography>
            </div>

            <div>
              <AddForm onSubmit={onSubmitCreate}>
                <FormControl required className="mb-3">
                  <FormLabel>Add sitemap url</FormLabel>
                  <Input placeholder="Your sitemap url" name="url" />
                  {errorMsg.type === "create" && (
                    <Alert variant="plain" color="danger">
                      {errorMsg.message}
                    </Alert>
                  )}
                </FormControl>
                <Button loading={loading.type === "create" ? loading?.bool : false} type="submit">
                  Add
                </Button>
              </AddForm>
            </div>
          </div>
        </Sheet>
      </Modal>
    </Root>
  );
}

const Root = styled.div`
  .gLkwvO div:first-child {
    width: 100%;
  }
  .eVTOiN {
    font-weight: 700;
    font-size: 13px;
  }
`;
const AddForm = styled.form`
  position: relative;
  /* background: #f4f4f4; */
`;
const List = styled.div`
  max-width: 1150px;
  /* max-width: fit-content; */
  margin: 1em auto;
`;
