import { Button, FormControl, FormHelperText, IconButton, Input, Option, Select, Table } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import TablePagination from "components/TablePagination";
import { upgradeToast } from "components/customToasts/UpgradeToast";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { getProjectMembersApi, inviteProjectMemberApi, removeProjectMemberApi } from "network/api/project/members";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiFillDelete, AiFillLock } from "react-icons/ai";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { ApiResType } from "types/enum";
import { MemberD, MemberRole } from "types/member";

const LIMIT = 5;
const COLS = ["ID", "Name", "Role", "Email", "Actions"];

export default function ProjectSettingMembers() {
  const { token } = useAuth();
  const { projectUid, isPro } = useProject();
  const navigate = useNavigate();

  const [list, setList] = useState<MemberD[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);
  const [role, setRole] = useState<MemberRole>(MemberRole.Viewer);
  const [loading, setLoading] = useState(false);

  //INVITE
  const [inviting, setInviting] = useState(false);
  const [invited, setInvited] = useState(false);
  const [email, setEmail] = useState("");

  const onInvite = async () => {
    if (!isPro) {
      upgradeToast({
        onUpgrade: () => navigate("/upgrade"),
      });
      return;
    }
    if (!token || !projectUid) return;
    if (!role) {
      toast.error("Please select a role");
      return;
    }

    try {
      setInviting(true);
      setInvited(false);
      const res = await inviteProjectMemberApi({
        email,
        project_uid: projectUid,
        role,
        token,
      });

      if (res.type === ApiResType.SUCCESS) {
        toast.success("Invite sent successfully");
        setEmail("");
        setRole(MemberRole.Viewer);
      }
      if (res.type === ApiResType.ERROR) {
        toast.error(res.message || "Something went wrong");
      }
      setInviting(false);
    } catch (err) {
      setInviting(false);
      console.error("Err", err);
    }
  };

  //REMOVE
  const [removeId, setRemoveId] = useState("");
  const [removing, setRemoving] = useState(false);
  const [removeError, setRemoveError] = useState("");

  const onRemove = async () => {
    if (!token || !projectUid) return;

    try {
      setRemoveError("");
      setRemoving(true);
      const res = await removeProjectMemberApi({ token, member_id: removeId, project_uid: projectUid });
      setRemoving(false);

      if (res.type === ApiResType.SUCCESS) {
        setList((s) => s.filter((i) => i.id.toString() !== removeId.toString()));
        setRemoveId("");
      }

      if (res.type === ApiResType.ERROR) {
        setRemoveError(res.message || "Something went wrong");
      }
    } catch (err) {
      setRemoveError("Something went wrong");
      setRemoving(false);
      console.error("Err", err);
    }
  };

  //LIST
  useEffect(() => {
    if (!token || !projectUid) return;

    const getOrganization = async () => {
      try {
        setLoading(true);
        const res = await getProjectMembersApi({ project_uid: projectUid, limit: LIMIT, page, token });
        if (res?.type === ApiResType.SUCCESS) {
          setList(res?.data);
        }
        setTotal(res?.total);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };

    getOrganization();
  }, [token, page, projectUid]);

  return (
    <div>
      {loading ? (
        <div className="p-10 flex items-center justify-center">
          <CgSpinner className="animate-spin text-5xl text-primary1" />
        </div>
      ) : (
        <div>
          <div className="mb-2">
            <div className="text-lg font-semibold">Members</div>
            <p className="text-sm m-0 p-0 text-gray-400">{total} members found</p>
          </div>

          <div className="flex mb-4 gap-3">
            <FormControl color={invited ? "success" : "neutral"} sx={{ flex: 1 }}>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} type={"email"} size="sm" sx={{ flex: 1 }} placeholder="Enter your email" />
              {invited && <FormHelperText>Invite sent to users's email</FormHelperText>}
            </FormControl>

            <FormControl size="sm">
              <Select placeholder="Choose role" name="role" onChange={(_, v) => setRole(v!)} value={role} sx={{ width: 120 }}>
                {Object.keys(MemberRole).map((i) => {
                  return (
                    <Option value={i} key={i}>
                      {i}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>

            <Button type="submit" size="sm" loading={inviting} onClick={onInvite}>
              {!isPro && <AiFillLock className="mr-1" />} <span>Invite</span>
            </Button>
          </div>

          <Table>
            <thead>
              <tr>
                {COLS.map((i, index) => (
                  <th key={index} style={{ ...(i === "ID" || i === "Actions" ? { width: "10%" } : i === "Email" ? { width: "40%" } : {}) }}>
                    {i}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {list.map((i) => {
                return (
                  <tr key={i?.id}>
                    <td>{i?.id}</td>
                    <td>{i?.user?.name}</td>
                    <td>{i?.role}</td>
                    <td>{i?.user?.email}</td>
                    <td>
                      <div className="actions cell flex gap-1">
                        <IconButton disabled={i?.role === MemberRole.Owner} variant="outlined" color="danger" onClick={() => setRemoveId(i?.id?.toString())}>
                          <AiFillDelete />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={COLS.length}>
                  <TablePagination {...{ limit, page, total }} onPage={setPage} onLimit={setLimit} />
                </td>
              </tr>
            </tfoot>
          </Table>

          <ConfirmModal error={removeError} open={removeId ? true : false} onClose={() => setRemoveId("")} onConfirm={onRemove} confirmTitle="Yes remove" loading={removing} danger title="Remove member ?" />
        </div>
      )}
    </div>
  );
}
