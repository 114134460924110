import { useEffect, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";

export default function CopyToClipboard({ text }: { text: string }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  return (
    <div
      className={`h-8 absolute top-3 right-3 cursor-pointer aspect-square text-white rounded flex items-center justify-center border ${copied ? "border-green-400/40 bg-green-400/20" : "border-white/40 bg-white/20"}`}
      onClick={() => {
        navigator.clipboard?.writeText(text);
        setCopied(true);
      }}
    >
      {copied ? <BsCheck className="text-xl" /> : <BiCopy />}
    </div>
  );
}
