import React from "react";
import { RiVipCrownFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";

export default function SettingLayout({ links, children }: { links: ({ label: string; link: string; } | null)[]; children: React.ReactNode }) {
  return (
    <div>
      <div className="text-gray-600 font-bold mb-6 px-2">Settings</div>
      <div className="w-full flex gap-4">
        <div style={{ minWidth: 240 }} className={" flex flex-col gap-2"}>
          {links.map((i) => {
            if(i) {
              return (
                <NavLink to={i.link} className={({ isActive }) => ` ${isActive ? "bg-gray-100 text-black" : "bg-transparent text-black/40"} hover:bg-gray-100 py-2 px-2 rounded-md transition-all font-semibold `}>
                  {
                    i.label !== 'Upgrade' ?
                    i.label :
                    <div className="flex gap-1"><RiVipCrownFill className="text-yellow-400 text-xl" /> <span className="text-inherit">{i.label}</span></div>
                  }
                </NavLink>
              )
            } else {
              return <></>
            }
          })}
        </div>

        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
}
