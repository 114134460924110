import { post } from "network";
import { ChatbotSettingD } from "types/project";

export const getMyIntegrationApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/getMyIntegration",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const getProjectIntegrationIdsApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/getProjectIntegrationIds",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const getOtherIntegrationApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/getOtherIntegration",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const enableChatbotApi = async ({ token, ...raw }: { token: string; project_uid: string }) => {
  return post({
    route: "/api/v1/chatbot/enableChatbot",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const getIntegrationSettingApi = async ({ token, ...raw }: { token: string; project_uid: string; integration_id: any }) => {
  return post({
    route: "/api/v1/getIntegrationSetting",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const updateChatbotSettingApi = async ({
  token,
  ...raw
}: {
  token: string;
  project_uid: string;
} & ChatbotSettingD) => {
  return post({
    route: "/api/v1/chatbot/updateChatbotSetting",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const enableDiscord = async ({ token, ...raw }: { token: string; project_uid: string; guild_id: string; permission?: "2147486720" }) => {
  return post({
    route: "/api/v1/discord/enableDiscord",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const enableSlack = async ({ token, ...raw }: { token: string; project_uid: string; code: string }) => {
  return post({
    route: "/api/v1/slack/enableSlack",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const disableIntegrationApi = async ({ token, ...raw }: { token: string; project_uid: string; integration_id: any }) => {
  return post({
    route: "/api/v1/disableIntegration",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
export const enableTelegramApi = async ({ token, ...raw }: { token: string; project_uid: string; telegram_user_id: string }) => {
  return post({
    route: "/api/v1/telegram/enableTelegram",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
