import { Alert, Button, Input } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import { tv } from "tailwind-variants";
import { ApiResType } from "types/enum";
import { sectionH, sectionV, sectionsV } from ".";
import { useProject } from "context/ProjectContext";
import { updateProjectSettingApi } from "network/api/project";

export default function AddOpenAIKey() {
  const { token } = useAuth();
  const { projectUid, setActiveProject, activeProject } = useProject();
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState("");
  const [error, setError] = useState("");
  const [key, setKey] = useState("");

  const onUpdate = async (event: any) => {
    event.preventDefault();

    try {
      setUpdating(true);
      setUpdateSuccess("");
      setError("");

      const res = await updateProjectSettingApi({
        token,
        project_uid: projectUid,
        openai_api_key: key,
      });

      setUpdating(false);
      if (res.type === ApiResType.SUCCESS) {
        setActiveProject((s) => ({ ...s, ProjectSetting: { ...s.ProjectSetting, openai_api_key: key } }));
        setUpdateSuccess("Details updated successfully");
        setTimeout(() => setUpdateSuccess(""), 2000);
      } else {
        setError(res.message);
      }
    } catch (err) {
      setError("Something went wrong");
      setUpdating(false);
      console.error("Err", err);
    }
  };

  useEffect(() => {
    setKey(activeProject?.ProjectSetting?.openai_api_key || "");
  }, [activeProject?.ProjectSetting?.openai_api_key]);

  return (
    <div>
      <div className={sectionsV()}>
        <div className={sectionV()}>
          <div className={sectionH()}>Your OpenAI key</div>
          <form className="flex flex-col gap-4" onSubmit={onUpdate}>
            <div className={fieldV()}>
              <div className={labelV()}>
                Open AI Key{" "}
                <a className="text-[hsl(212,92%,40%)]" href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">
                  (Create a new OpenAI key)
                </a>
              </div>
              <Input
                required
                value={key}
                sx={{ fontSize: "14px" }}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
                placeholder="************************************"
                name="openAiKey"
                variant="soft"
              />
            </div>

            {updateSuccess && (
              <Alert sx={{ py: 0, px: 0 }} variant="plain" color="success">
                {updateSuccess}
              </Alert>
            )}
            {error && (
              <Alert sx={{ py: 0, px: 0 }} variant="plain" color="danger">
                {error}
              </Alert>
            )}

            <div className="self-start">
              <Button type="submit" loading={updating}>
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const fieldV = tv({
  base: "",
});
const labelV = tv({
  base: "text-sm font-semibold opacity-60 mb-1",
});
