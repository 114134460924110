import { TextareaAutosize } from "@mui/base";
import React, { useState } from "react";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { IoAddCircle } from "react-icons/io5";
import { tv } from "tailwind-variants";

export type ChatItemD = {
  id: number;
  type: "user" | "assistant" | "system";
  text: string;
};

const ITEMS: ChatItemD[] = [
  {
    id: 1,
    type: "user",
    text: "Hello",
  },
  {
    id: 2,
    type: "assistant",
    text: "How are you",
  },
];

const itemCommonT = tv({
  base: "group flex gap-2 py-4 px-2 items-start hover:bg-gray-100 transition-all",
});

export default function ChatList({ list, onNew, onRemove, onToggle, onTextChange }: { list: ChatItemD[]; onNew: () => any; onToggle: (id: any) => any; onTextChange: (d: { id: any; text: string }) => any; onRemove: (id: any) => any }) {
  const [items, setItems] = useState<ChatItemD[]>([]);

  return (
    <div className="divide-y divide-gray-200">
      {list.map((i) => {
        return (
          <div className={itemCommonT()}>
            <div onClick={() => onToggle(i.id)} className=" min-w-[90px] flex justify-center font-semibold group-hover:bg-gray-300 px-2 h-11 items-center cursor-pointer rounded-md text-sm">
              {i.type.toUpperCase()}
            </div>
            <div className="flex-1">
              <TextareaAutosize
                value={i.text}
                onChange={(e) => {
                  onTextChange({ text: e.target.value, id: i.id });
                }}
                placeholder={`Enter ${i?.type || "user"} mesasge here`}
                className="w-full resize-none bg-transparent border-solid focus:border-blue-500 border-transparent rounded-md focus:bg-white px-2 py-2 border outline-none"
              />
              {/* <input /> */}
            </div>

            <div onClick={() => onRemove(i.id)} className="group-hover:visible invisible transition-all h-11 px-2 items-center flex justify-center cursor-pointer text-gray-500 hover:text-red-500">
              <AiOutlineMinusCircle size={20} />
            </div>
          </div>
        );
      })}

      <div
        onClick={onNew}
        className={itemCommonT({
          class: "items-center cursor-pointer opacity-60 justify-center hover:opacity-100",
        })}
      >
        <IoAddCircle size={20} className="text-gray-600" />
        <div>Add message</div>
      </div>
    </div>
  );
}
