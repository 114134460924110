import { Button, CircularProgress, IconButton, Sheet, Typography } from "@mui/joy";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { AiFillEye } from "react-icons/ai";
import { SortD } from "types";
import { useListingApi } from "hooks/useListingApi";
import { FineTuneItemD } from "types/fineTune";
import { formatDateTime, logErr } from "utils/helpers";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useProject } from "context/ProjectContext";
import { appContent } from "components/variants/app";
import ConfirmModal from "components/ConfirmModal";
import { FcCancel } from "react-icons/fc";
import { cancelFineTuneApi } from "network/api/project/fineTune";
import { useAuth } from "context/AuthContext";
import styled from "styled-components";

export default function ProjectFineTunes() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectUid } = useProject();
  const { token } = useAuth();

  const [cancelId, setCancelId] = useState("");
  const [canceling, setCanceling] = useState(false);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortD>("desc");
  const [listType, setListType] = useState<"fineTuneJobs" | "models">("fineTuneJobs");

  const {
    data,
    total,
    apiError,
    loading,
    setData,
    setTotal,
  }: {
    data: FineTuneItemD[];
    total: number;
    apiError: string;
    loading: boolean;
    setData: any;
    setTotal: any;
  } = useListingApi({
    limit,
    page,
    search,
    sort,
    type: listType,
    project_uid: projectUid,
  });

  const COLS: {
    name: string;
    wrap: boolean;
    selector: (d: FineTuneItemD) => any;
  }[] = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        wrap: true,
      },
      {
        name: "Modal",
        selector: (row) => row.model,
        wrap: true,
      },
      {
        name: "Name",
        selector: (row) => row.fine_tuned_model,
        wrap: true,
      },
      {
        name: "Created at",
        selector: (row) => formatDateTime(row.created_at),
        wrap: true,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        wrap: true,
      },
      {
        name: "Actions",
        selector: (row) => (
          <>
            <div className="flex gap-1">
              {/* <IconButton variant="outlined" color="danger" onClick={() => setDeleteId(row.id)}>
                <AiFillDelete />
              </IconButton> */}
              <IconButton
                variant="solid"
                color="primary"
                onClick={() => {
                  navigate(`${pathname}${listType === "fineTuneJobs" ? "-jobs/" : "/"}${row.id}`);
                }}
              >
                <AiFillEye />
              </IconButton>
              {row.status === "pending" && (
                <IconButton variant="outlined" color="danger" onClick={() => setCancelId(row.id)}>
                  <FcCancel />
                </IconButton>
              )}
            </div>
          </>
        ),
        wrap: true,
      },
    ],
    [navigate, pathname, listType]
  );

  const onCancel = async (id: string) => {
    try {
      setCanceling(true);

      const data: any = {
        project_uid: projectUid,
        fine_tune_id: id,
      };
      const formData = new URLSearchParams();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      // await cancelFineTuneApi({token, raw: formData.toString()});
      await cancelFineTuneApi({ token, raw: { project_uid: projectUid, fine_tune_id: id } });
      setCanceling(false);
      setCancelId("");
    } catch (err) {
      setCanceling(false);
      logErr("Err", err);
    }
  };

  return (
    <Root className={appContent()}>
      <div className="flex justify-between items-center">
        <div className="right">
          <Typography level="title-lg">Your OpenAI Fine-tunes</Typography>
          <Typography level="body-md">{data?.length} results</Typography>
        </div>

        <Tabs
          aria-label="tabs"
          size="sm"
          sx={{ bgcolor: "transparent" }}
          className="tabs-container"
          onChange={(_, v) => {
            setListType(v as "fineTuneJobs" | "models");
          }}
          defaultValue={"fineTuneJobs"}
        >
          <TabList
            sx={{
              p: 0.5,
              gap: 0.5,
              borderRadius: "md",
              bgcolor: "background.level1",
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: "sm",
                bgcolor: "background.surface",
              },
            }}
          >
            <Tab value={"fineTuneJobs"}>GPT-3.5 & Above</Tab>
            <Tab value={"models"}>Others</Tab>
          </TabList>
        </Tabs>

        <div className="right flex items-start gap-2">
          <Button size="md" startDecorator={<FiPlus />} onClick={() => navigate(pathname + "/add")}>
            Add new
          </Button>
        </div>
      </div>
      {/* HEADER END */}

      <div>
        <Sheet>
          {loading ? (
            <div className="flex h-20 items-center justify-center">
              <CircularProgress size="md" />
            </div>
          ) : (
            <>
              <DataTable columns={COLS} data={data} pagination paginationRowsPerPageOptions={[10, 50, 100]} paginationPerPage={10} />
            </>
          )}
        </Sheet>
      </div>

      <ConfirmModal
        loading={canceling}
        onClose={() => setCancelId("")}
        onConfirm={() => onCancel(cancelId)}
        open={cancelId ? true : false}
        title="Confirmation"
        desc="Are you sure you want to cancel generating this Fine-tune?"
        danger
        confirmTitle="Cancel generating this Fine-tune"
      />
    </Root>
  );
}

const Root = styled.div`
  .tabs-container *::after {
    display: none !important;
  }
`;
