import { Autocomplete } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { getAllModelsApi } from "network/api/project/model";
import { useCallback, useEffect, useState } from "react";
import { ModelItemD } from "types/model";
import { Mode } from "types/playground";
import { logErr } from "utils/helpers";

export default function ModelSelect({
  value,
  onLoadStart,
  onLoad,
  onChange,
  autoSet = true,
  name,
  type,
  mode,
}: {
  onLoadStart?: () => any;
  onLoad?: (d: ModelItemD) => any;
  value: ModelItemD | null;
  onChange: (d: ModelItemD) => any;
  autoSet: boolean;
  name?: string;
  type?: "fineTune" | "dataset" | null;
  mode?: Mode;
}) {
  const { token } = useAuth();
  const { projectUid } = useProject();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(value);
  const [models, setModels] = useState<ModelItemD[]>([]);
  const [search, setSearch] = useState("");

  const FINE_TUNE_MODELS = ["davinci", "curie", "babbage", "ada", "gpt-3.5-turbo"];
  const DATASET_MODELS = ["davinci", "curie", "babbage", "ada", "gpt-3.5-turbo"];

  const fetchModel = useCallback(async () => {
    let active = true;

    if (!token || !projectUid) {
      return;
    }

    try {
      setLoading(true);

      if (active) {
        const res = await getAllModelsApi({
          limit: 10,
          page: 1,
          project_uid: projectUid,
          search,
          token,
        });

        if (res?.type === "RXSUCCESS") {
          if (autoSet) {
            onChange(res?.data[0]);

            // if (mode === Mode.chat) {
            //   console.log(
            //     'res?.data.find((i: any) => i.id === "gpt-3.5-turbo")',
            //     res?.data.find((i: any) => i.id === "gpt-3.5-turbo")
            //   );
            //   setModel(res?.data.find((i: any) => i.id === "gpt-3.5-turbo"));
            // } else {
            setModel(res?.data[0]);
            // }
          }
          setModels(res?.data);
        }
      }
    } catch (err) {
      setLoading(false);
      logErr("Err", err);
    }
    return () => {
      active = false;
    };
  }, [search, token, projectUid]);

  useEffect(() => {
    fetchModel();
  }, [fetchModel]);

  useEffect(() => {
    setModel(value);
  }, [value]);

  useEffect(() => {
    if (mode === Mode.chat && models?.find((i: any) => i.id === "gpt-3.5-turbo") !== undefined) {
      setModel(models.filter((i) => ["gpt-3.5-turbo"].includes(i.id))[0]);
    }
  }, [mode]);

  useEffect(() => {
    if (loading && onLoadStart) {
      onLoadStart();
    }
  }, [loading]);

  console.log("model", model);

  let filteredOptions = models;

  if (type === "fineTune") {
    filteredOptions = models.filter((i) => FINE_TUNE_MODELS.includes(i.id));
  }
  if (type === "dataset") {
    filteredOptions = models.filter((i) => DATASET_MODELS.includes(i.id));
  }

  return (
    <div>
      <Autocomplete
        size="sm"
        multiple={false}
        name={name}
        options={filteredOptions}
        placeholder="Select model"
        value={model}
        onChange={(_: any, val) => {
          if (val) {
            setModel(val);
            onChange(val);
          }
        }}
        loading={loading}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        getOptionLabel={(option: ModelItemD) => {
          return option.id;
        }}
      />
    </div>
  );
}
