import { ModalClose } from "@mui/joy";
import CopyToClipboard from "components/CopyToClipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styled from "styled-components";

export default function OpenAI() {
  const textContent = `import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.OPENAI_API_KEY,
});

const chatCompletion = await openai.chat.completions.create({
  messages: [{ role: "user", content: "Say this is a test" }],
  model: "gpt-3.5-turbo"
});`;

  return (
    <Root>
      <div className="text-xl font-bold">OpenAI Integration</div>
      <div className="text-black/70 text-sm">Integrate with OpenAI's technology seamlessly.</div>

      <div className="relative">
        <div className="rounded-lg relative mt-10 bg-slate-900 text-white font-mono px-3 text-[15px] overflow-x-auto block">
          <SyntaxHighlighter language="javascript" style={dark} customStyle={{ background: "transparent", border: "none", boxShadow: "none" }}>
            {textContent}
          </SyntaxHighlighter>
        </div>
        <CopyToClipboard text={textContent} />
      </div>
      <ModalClose variant="outlined" />
    </Root>
  );
}

const Root = styled.div`
  [style*="color: rgb(209, 148, 158);"] {
    color: rgb(236 72 153) !important;
  }
  [style*="color: rgb(189, 224, 82);"] {
    color: rgb(74 222 128) !important;
  }
  .module,
  .literal-property {
    color: rgb(96 165 250) !important;
  }
`;
