import { Button, CircularProgress, Divider, IconButton, Modal, ModalClose, Sheet, Typography } from "@mui/joy";
import LinearProgress from "@mui/joy/LinearProgress";
import React, { useEffect, useState } from "react";
import { isValidFileType, log, logErr } from "utils/helpers";
import { RiCloseFill } from "react-icons/ri";
import { jsonl } from "js-jsonl";
import { getProjectFileSignedUrlApi, uploadProjectFileAdvanceApi, uploadProjectFileApi } from "network/api/project/file";
import axios from "axios";
import toast from "react-hot-toast";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { useNavigate } from "react-router-dom";
import { appContent } from "components/variants/app";
import BackHeader from "components/BackHeader";
import styled from "styled-components";
import { ApiResType, Purpose } from "types/enum";
import { FaUpload } from "react-icons/fa";
import { ReactComponent as Logo } from "../../../../assets/svg/logo.svg";

let controller: any;

export default function ProjectFiledAdd() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { projectUid, activeProject } = useProject();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [fileText, setFileText] = useState<any>("");
  const [promptCount, setPromptCount] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // FUNCTIONS
  const onFile = async (e: any) => {
    if (e.target.files[0]) {
      const fileSize = e.target.files[0].size / 1024 / 1024;
      const maxFileSize = activeProject?.ProjectUsage?.plan === "basic" ? 1 : 10;

      if (activeProject?.purpose === Purpose.fineTuning) {
        setFile(e.target.files[0]);
        return;
      }

      if (fileSize <= maxFileSize) {
        if (!isValidFileType(e.target.files[0]?.name)) {
          alert("Not a valid file type");
        } else {
          setFile(e.target.files[0]);
        }
      } else {
        // if(maxFileSize === 1) {
        //   toast(<div className="">
        //     <p className="mb-3">Your max file size limit is 1mb. Upgrade to upload files upto 10mb.</p>
        //     <div className="flex items-center gap-3">
        //       <Button size="sm" onClick={() => {
        //         navigate('/projects/'+projectUid+'/settings/upgrade')
        //         toast.dismiss()
        //         }}>Upgrade now</Button>
        //       <Button variant="plain" onClick={() => {toast.dismiss()}}>Cancel</Button>
        //     </div>
        //   </div>
        //   , {
        //   duration: Infinity
        //   })
        // }
        if (maxFileSize === 10) {
          toast.error("Max file size exceeded - 10mb");
        }
      }
    }
  };

  const cancelUpload = () => {
    controller?.abort();
  };

  const onUpload = async () => {
    try {
      if (!file) {
        return;
      }

      setUploading(true);
      const res1 = await getProjectFileSignedUrlApi({
        file_name: file.name,
        project_uid: projectUid,
        token,
      });

      controller = new AbortController();
      const config = {
        signal: controller.signal,
        onUploadProgress: (progressEvent: any) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
        headers: {
          "Content-Type": "application/octet-stream",
        },
      };

      await axios.put(res1?.data?.url, file, config);

      let res;

      if (activeProject?.purpose === Purpose.fineTuning) {
        res = await uploadProjectFileApi({
          token,
          file_name: res1?.data?.filename,
          original_filename: file.name,
          project_uid: projectUid,
        });
      } else {
        res = await uploadProjectFileAdvanceApi({
          token,
          file_name: res1?.data?.filename,
          original_filename: file.name,
          project_uid: projectUid,
        });
      }

      log(res);

      if (res.type === ApiResType.SUCCESS) {
        toast("File uploaded", {
          icon: "✅",
        });
        navigate(`/projects/${projectUid}/train/files`);
      } else if (res.message) {
        toast(res.message, {
          icon: "❌",
          duration: 5000,
        });
      }

      setUploading(false);
    } catch (err) {
      setUploading(false);

      logErr("Err", err);
    }
  };

  // SIDE EFFECTS
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     setReader(new FileReader());
  //   }
  // }, []);

  // useEffect(() => {
  //   const handleDrop = (event: DragEvent) => {
  //     event.preventDefault();
  //     const files = event.dataTransfer?.files;
  //     reader?.readAsText(files![0]);
  //   };

  //   if(document) {
  //     document.addEventListener('drop', handleDrop);
  //   }
  //   return () => {
  //     if(document) {
  //       document.removeEventListener('drop', handleDrop)
  //     }
  //   }
  // }, [reader]);

  // useEffect(() => {
  //   let handleDragOver = (event: DragEvent) => {
  //     event.preventDefault();
  //   };

  //   if(document) {
  //     document.addEventListener('dragover', handleDragOver);
  //   }

  //   return () => {
  //     if(document) {
  //       document.removeEventListener('dragover', handleDragOver)
  //     }
  //   };
  // }, []);

  // if(reader) {
  //   reader.onload = (e: any) => {
  //     if (e.target) {
  //       setFile(e.target.result);
  //     }
  //   };
  // };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setFileText(reader.result);
          onUpload();
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    } else {
      setFileText("");
    }

    return () => {};
  }, [file]);

  useEffect(() => {
    if (fileText) {
      try {
        setPromptCount(jsonl.parse(fileText).length);
      } catch (err) {
        logErr("ER", err);
      }
    }
  }, [fileText]);

  if (activeProject?.purpose === Purpose.fineTuning) {
    return (
      <div className="bg-gray-50">
        <div className={appContent()}>
          <BackHeader title="Create new file" desc="Create new file and upload them to your OpenAI account" />

          <Divider />

          <div className="mt-4 mb-8 flex flex-col gap-4 ">
            <div className="left flex-1">
              {/* <div className="mb-4">
                <a target={"_blank"} className={"text-sm flex gap-1 items-center text-blue-500 font-semibold"} href="https://yourgpt.ai/tools/openai-fine-tune-csv-to-json-line">
                  <AiFillTool size={18} />
                  Convert your CSV Dataset to OpenAI Supported File
                </a>
              </div> */}

              {file ? (
                <div className=" mb-2 flex items-center gap-2  rounded-lg border border-solid border-gray-300 bg-gray-100 p-2 pl-3 ">
                  <div className="flex-1 ">{file?.name}</div>
                  <div>
                    <IconButton
                      sx={{ borderRadius: 120 }}
                      color="danger"
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <RiCloseFill />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-3">
                  <Button component="label">
                    Upload JSONL file
                    <input hidden accept="application/jsonl" type="file" onChange={onFile} />
                  </Button>
                  <Button component="label" variant="outlined">
                    <a href="https://yourgpt.ai/tools/openai-fine-tune-csv-to-json-line" target="_blank" rel="noreferrer" className="flex items-center justify-center gap-2">
                      Convert CSV Dataset
                    </a>
                  </Button>
                </div>
              )}
            </div>

            {file && (
              <div className="flex-1">
                <div className="mb-4">
                  <span className="text-gray-500">Your total propmpt completion pairs :</span>
                  <b> {promptCount}</b>
                </div>

                <div className="flex items-center gap-2">
                  {uploading && (
                    <CircularProgress determinate value={uploadProgress}>
                      {uploadProgress}%
                    </CircularProgress>
                  )}
                  <Button size="lg" onClick={onUpload} disabled={uploading}>
                    {uploading ? "Uploading to OpenAI" : "Upload file to OpenAI"}
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="mt-2">
            <div className="mb-4">
              <Typography level="h4">Data</Typography>
              <Typography color="neutral">Example data vs your data</Typography>
            </div>

            <div className=" mb-4 rounded-lg bg-white  p-4">
              <Typography fontWeight={"lg"} sx={{ mb: 1 }}>
                Example prompt-completion pairs
              </Typography>

              <Typography level="body-md" sx={{ whiteSpace: "pre-wrap" }}>
                {`{"prompt": "<prompt text><suffix>", "completion": "<space><ideal generated text><stop sequence>"}
  {"prompt": "<prompt text><suffix>", "completion": "<space><ideal generated text><stop sequence>"}
  {"prompt": "<prompt text><suffix>", "completion": "<space><ideal generated text><stop sequence>"}
  ...
  `}
              </Typography>
            </div>
            <div className=" rounded-lg bg-white p-4">
              <Typography fontWeight={"lg"} sx={{ mb: 1 }}>
                Your prompt-completion pairs
              </Typography>

              <div>
                <Typography level="body-md" sx={{ whiteSpace: "pre-wrap" }}>
                  {fileText}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={uploadOpen} onClose={() => setUploadOpen(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Sheet
            variant="outlined"
            color="primary"
            sx={{
              maxWidth: 500,
              width: "90%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose
              variant="outlined"
              // sx={{
              //   top: 'calc(-1/4 * var(--IconButton-size))',
              //   right: 'calc(-1/4 * var(--IconButton-size))',
              //   boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              //   borderRadius: '50%',
              //   bgcolor: 'background.body',
              // }}
            />

            <div className="mb-3">
              <Typography fontWeight={"lg"}>Upload CSV</Typography>
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <Button variant="soft" size="lg" sx={{ width: "100%" }}>
                  Clcik to upload CSV file
                </Button>
              </div>

              <div>
                <LinearProgress
                  determinate
                  variant="outlined"
                  color="neutral"
                  size="sm"
                  thickness={32}
                  value={50}
                  sx={{
                    "--LinearProgress-radius": "12px",
                    "--LinearProgress-progressThickness": "24px",
                    boxShadow: "sm",
                    borderColor: "black",
                  }}
                >
                  <Typography level="body-sm" fontWeight="xl" textColor="common.white" sx={{ mixBlendMode: "difference" }}>
                    UPLOAD.... {`${Math.round(50)}%`}
                  </Typography>
                </LinearProgress>
              </div>
            </div>
          </Sheet>
        </Modal>
      </div>
    );
  } else if (activeProject?.purpose) {
    return (
      <Root className={appContent({})}>
        {/* <h1>Upload a file to train your chatbot</h1> */}
        <InputBox>
          <div className="text-5xl mb-8 mt-10">
            {/* 🫵🏼 */}
            <Logo className="h-10 w-auto opacity-30" />
          </div>
          <Btn>
            <FaUpload /> Upload File
            <input type="file" accept="text/plain,.txt, .pdf, .doc, .docx, .ppt, .csv" onChange={onFile} />
          </Btn>
          <div className="mt-2 text-lg opacity-60">or drop a file</div>
          <input type="file" accept="text/plain,.txt, .pdf, .doc, .docx, .ppt, .csv" className="absolute top-0 left-0 w-full h-full opacity-0" onChange={onFile} />

          {uploading && (
            <Overlay>
              <div className="w-[300px] mx-auto">
                <CircularProgress size="lg" determinate value={uploadProgress}>
                  {uploadProgress}%
                </CircularProgress>
              </div>
              <div className="flex items-center justify-center gap-3">
                <Button size="md" variant="plain" color="danger" onClick={cancelUpload}>
                  Cancel
                </Button>
              </div>
            </Overlay>
          )}
        </InputBox>
      </Root>
    );
  } else {
    return <></>;
  }
}

const FileInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed #bcbcbc;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.44);
  padding: 4em 2em;
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
`;
const Root = styled.div`
  text-align: center;
  h1 {
    /* font-size: 2.25rem; */
    font-size: 20px;
    /* font-weight: bold;  */
    opacity: 70%;
    max-width: 700px;
    line-height: 105%;
    margin: 0 auto;
  }
`;
const InputBox = styled.div`
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  padding: 2em;
  margin: 2em auto;
  overflow: hidden;
`;
const Btn = styled.button`
  display: inline-block;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  color: #fff;
  background-color: #1865ea;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.25rem;
  font-weight: 600;

  &:hover {
    background-color: #134fb5;
    transition: all 0.3s ease;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
  }
`;
const Overlay = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: column;
  padding: 1em;
  /* align-items: center; */
  justify-content: center;
  gap: 1em;
`;
