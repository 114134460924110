import styled from "@emotion/styled";
import { appContent } from "components/variants/app";
import IntegrationCard from "./Card";
import { SiOpenai, SiZapier } from "react-icons/si";

export default function IntegrationList() {
  const integrationArr = [
    {
      id: 5,
      name: "Zapier Integration",
      icon: <SiZapier />,
      description: "",
      details: "",
      type: "zapier",
      color: "#e63900",
    },
    {
      id: 6,
      name: "OpenAI Integration",
      icon: <SiOpenai />,
      description: "",
      details: "",
      type: "openai",
      color: "#19c37d",
    },
  ];

  return (
    <div>
      <div className={appContent({ place: "console" })}>
        <h2 className="text-xl font-bold">Integration</h2>
        <p>Easily integrate YourGPT with your favorite tools and apps for seamless collaboration.</p>

        <Grid>
          {integrationArr.map((i) => (
            <IntegrationCard key={i?.id} item={i} />
          ))}
        </Grid>
      </div>
    </div>
  );
}

const Grid = styled.div`
  margin: 2em auto;
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
`;
