import { useProject } from "context/ProjectContext";
import { AiFillLock } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DatasetItemD } from "types/dataset";

export default function Card({ dataset }: { dataset: DatasetItemD }) {
  const { isPro } = useProject();
  const navigate = useNavigate();

  const downloadJsonFile = async () => {
    try {
      const response = await fetch(dataset?.url);
      const jsonData = await response.json();

      if (jsonData) {
        const blob = new Blob([JSON.stringify(jsonData)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "sales.json";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error fetching JSON data:", error);
    }
  };

  return (
    <div className="border relative rounded-lg p-4 flex flex-col gap-3 hover:bg-black/5 transition-all">
      {isPro ? (
        <FaDownload className="absolute top-3 right-3 text-xl text-black/40 cursor-pointer" onClick={downloadJsonFile} />
      ) : (
        <AiFillLock className="absolute top-3 right-3 text-xl text-black/40 cursor-pointer" onClick={() => navigate("/upgrade")} />
      )}

      <h3 className="font-bold text-black/70">{dataset?.title}</h3>
      <p className="text-black/70 text-[15px]">{dataset?.description}</p>
    </div>
  );
}
