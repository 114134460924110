import { getProjectDetailApi } from "network/api/project";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiResType } from "types/enum";
import { ProjectDetailD } from "types/project";
import { useAuth } from "./AuthContext";
import { getActivePlanDetailApi } from "network/api/project/subscription";
import { getOrganisationApi } from "network/api/organization";
import { ActivePlanDetailD, SubscriptionStatus } from "types/subscription";
import { OrganizationD } from "types/org";

type MProjectItemD = ProjectDetailD & {
  loading?: boolean;
  ProjectUsage: {
    document_count: number;
    next_cycle: number;
    plan: "starter_monthly" | "growth_monthly" | "professional_monthly" | "basic";
    query_count: number;
  };
};

type ProjectContextD = {
  projectUid: any;
  activeProject: MProjectItemD;
  setActiveProject: React.Dispatch<React.SetStateAction<MProjectItemD>>;
  organization: any;
  isPro?: boolean;
  activePlanDetails: ActivePlanDetailD | null;
};

const ProjectContext = createContext<ProjectContextD>({} as ProjectContextD);

export default function ProjectProvider({ children }: { children: React.ReactNode }) {
  const { projectUid } = useParams();
  const { token } = useAuth();
  const [activeProject, setActiveProject] = useState<MProjectItemD>({} as MProjectItemD);
  const [activePlanDetails, setActivePlanDetails] = useState<ActivePlanDetailD | null>(null);
  // const [planLoading, setPlanLoading] = useState(true);
  const [organization, setOrganization] = useState<OrganizationD | null>(null);

  const fetchProjectDetail = useCallback(async () => {
    try {
      if (!projectUid || !token) {
        return;
      }

      setActiveProject((s) => ({ ...s, loading: true }));

      const res = await getProjectDetailApi({ token, project_uid: projectUid });

      if (res.type === ApiResType.SUCCESS) {
        setActiveProject((s) => ({ ...s, ...res.data, loading: false }));
      }

      if (res.type === ApiResType.ERROR) {
        setActiveProject(res.data);
      }
    } catch (err) {
      setActiveProject((s) => ({ ...s, loading: false }));
    }
  }, [projectUid, token]);

  useEffect(() => {
    fetchProjectDetail();
  }, [fetchProjectDetail]);

  useEffect(() => {
    if (!token) return;

    const fetchActivePlanDetails = async () => {
      try {
        // setPlanLoading(true);
        const orgRes = await getOrganisationApi({
          limit: 1,
          page: 1,
          token,
        });

        if (orgRes?.type === ApiResType.SUCCESS) {
          setOrganization(orgRes?.data[0]);
          const res = await getActivePlanDetailApi({
            token,
            type: "organization",
            id: orgRes?.data[0]?.id.toString(),
          });
          // setPlanLoading(false);

          if (res?.type === ApiResType.SUCCESS) {
            setActivePlanDetails(res?.data);
          } else if (res?.type === ApiResType.ERROR) {
            setActivePlanDetails(null);
          }
        }
      } catch (err) {
        // setPlanLoading(false);
        console.log("Err", err);
      }
    };

    fetchActivePlanDetails();
  }, [token]);

  return <ProjectContext.Provider value={{ projectUid, activeProject, setActiveProject, organization, isPro: activePlanDetails?.status === SubscriptionStatus.active, activePlanDetails }}>{children}</ProjectContext.Provider>;
}

export const useProject = () => {
  return useContext(ProjectContext);
};
