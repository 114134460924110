"use client";

import { Alert, Box, Button, Divider, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import React from "react";
import { AiFillWarning } from "react-icons/ai";

export default function ConfirmModal({
  open,
  onClose,
  title,
  desc,
  loading = false,
  onConfirm,
  danger = false,
  confirmTitle,
  error = "",
}: {
  open: boolean;
  loading?: boolean;
  cancelTitle?: string;
  confirmTitle?: string;
  title?: string;
  desc?: string;
  danger?: boolean;
  onClose: () => any;
  onConfirm: () => any;
  error?: string;
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog" aria-labelledby="alert-dialog-modal-title" aria-describedby="alert-dialog-modal-description">
        <Typography id="alert-dialog-modal-title" component="h2" fontWeight={"lg"} startDecorator={<AiFillWarning />}>
          {title}
        </Typography>
        <Divider />

        {desc && (
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            {desc}
          </Typography>
        )}

        {error && (
          <Alert sx={{ py: 0, px: 0, mt: 2 }} variant="plain" color="danger">
            {error}
          </Alert>
        )}

        <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}>
          <Button variant="plain" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="solid" loading={loading} color={danger ? "danger" : "primary"} onClick={onConfirm}>
            {confirmTitle || "Confirm"}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
