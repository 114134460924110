import { Menu, MenuItem } from '@mui/joy';
import { useAuth } from 'context/AuthContext';
import { useProject } from 'context/ProjectContext';
import { getDashboardStatsApi } from 'network/api/project/dashboard';
import { useEffect, useState } from 'react';
// import { IoFilter } from 'react-icons/io5';
// import { RiArrowDownSLine } from 'react-icons/ri';
import { logErr } from 'utils/helpers';

export default function Overview() {
    const {token} = useAuth();
    const { projectUid } = useProject();
    // const [anchorEl, setAnchorEl] = useState<any>(null);
    // const menuOpen = Boolean(anchorEl); 
    const [data, setData] = useState<{query_count: string, session_count: string}[]>([{query_count: "", session_count: ""}]);

    useEffect(() => {
        if(!token) return;

        const getSessions = async () => {
            try {  
                const res = await getDashboardStatsApi({token, data: { projectUid }}); 
                setData(res?.data); 
            } catch (err) { 
                logErr('Err', err);
            }
        }

        getSessions();
    }, [token, projectUid]);

  return (
    <div>
         <nav className="flex justify-between items-end">
            <h2 className='font-medium text-black/70 text-2xl'>Overview</h2>
            {/* <div>
                <div className="flex items-center gap-2 cursor-pointer text-black/70" onClick={(e) => setAnchorEl(e.currentTarget)}> 
                    <IoFilter />
                    <span>Filter</span> 
                    <RiArrowDownSLine className={`${menuOpen ? "rotate-180 duration-200" : ""}`} />
                </div> 
                <Menu 
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(null)}
                    sx={{
                    minWidth: 280,
                    }}
                > 
                    <MenuItem
                        sx={{":focus-visible": {outline: "none"}}}
                        color="neutral"
                        onClick={() => {}}
                    >
                    Sort by date
                    </MenuItem>
                    <MenuItem
                        sx={{":focus-visible": {outline: "none"}}}
                        color="neutral"
                        onClick={() => {}}
                    >
                    Sort by status
                    </MenuItem>
                </Menu>
            </div> */}
        </nav>

        <div className='my-6 flex flex-wrap gap-5 pb-10 border-b'>
            <Card title='Total Sessions' desc={data?.length > 0 ? data[0]?.session_count : '0'} info='In total' />
            <Card title='Total Queries' desc={data?.length > 0 ? data[0]?.query_count : '0'} info='In total' />
        </div>
    </div>
  )
}

// function Card({title, desc, info}:{title: string, desc: string, info: string}) {
//     return (
//         <div className='bg-blue-600/30 rounded-xl flex flex-col min-w-[220px] w-4/5 max-w-[300px] shadow-md border justify-center text-center py-10'>
//             <div className='flex flex-col gap-1 px-5'>
//                 <strong className="font-normal text-5xl">{desc}</strong> 
//             </div>
//             <div className="px-5 pb-2">
//                 <div className='font-bold'>{title}</div>
//             </div>
//         </div> 
//     )
// }
// function Card({title, desc, info}:{title: string, desc: string, info: string}) {
//     return (
//         <div className='bg-white rounded-xl flex flex-col min-w-[250px] w-4/5 max-w-[300px] shadow-md border border-gray-50'>
//             <div className="px-5 pt-5 pb-2 mb-2 text-blue-600">
//                 <div className='font-bold'>{title}</div>
//             </div>
//             <div className='flex flex-col gap-1 px-5 pb-5'>
//                 <strong className="font-normal text-3xl">{desc}</strong>
//                 {/* <small className='text-sm'>{info}</small> */}
//             </div>
//         </div> 
//     )
// }
function Card({title, desc, info}:{title: string, desc: string, info: string}) {
    return (
        <div className='bg-white rounded-xl flex flex-col min-w-[250px] w-4/5 max-w-[300px] shadow-md border border-gray-50' 
        // style={{boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"}}
        >
            <div className="border-b-[1px] px-5 pt-5 pb-2 mb-2">
                <p className='font-bold text-black/70'>{title}</p>
            </div>
            <div className='flex flex-col gap-1 px-5 pb-5'>
                <strong className="font-normal text-3xl">{desc}</strong>
                <small className='text-sm'>{info}</small>
            </div>
        </div> 
    )
}
