import { Button } from "@mui/joy";
import { toast } from "react-hot-toast";

export const upgradeToast = ({ text = "Upgrade to professional to unlock this feature.", onUpgrade }: { text?: string; onUpgrade: () => void }) => {
  return toast(
    <div className="">
      <p className="mb-3">{text}</p>
      <div className="flex items-center gap-3">
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            onUpgrade();
            toast.dismiss();
          }}
        >
          Upgrade now
        </Button>
        <Button
          color="primary"
          variant="plain"
          onClick={() => {
            toast.dismiss();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>,
    {
      //   icon: <AiFillLock className="text-2xl" />,
      duration: Infinity,
    }
  );
};
