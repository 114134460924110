import { useProject } from "context/ProjectContext";
import FineTuneList from "./FineTuneList";
import { Purpose } from "types/enum";
import List from "./List";

export default function ProjectFiles() { 
  const { activeProject } = useProject();

  return (
    <>
      {
        activeProject?.purpose === Purpose.fineTuning ?
        <FineTuneList /> :
        <List />
      } 
    </>
  );
}
