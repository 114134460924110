import { Chip, IconButton, Table, Typography } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { getFineTuneDetailApi } from "network/api/project/fineTune";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { FineTuneD } from "types/fineTune";
import { formatDateTime, logErr } from "utils/helpers";
import Events from "./Events";
import Result from "./Result";
import Training from "./Training";
import { useProject } from "context/ProjectContext";
import { appContent } from "components/variants/app";
import { FixedOverlayLoader } from "components/OverlayLoader";
import { getFineTuneJobDetailApi, getFineTuneJobEventsApi } from "network/api/project/fineTuneJobs";

export default function FileTuneDetail({ isJob = false }: { isJob?: boolean }) {
  const navigate = useNavigate();
  const { projectUid } = useProject();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const { fineTuneId } = useParams();
  const [fineTune, setFineTune] = useState<FineTuneD>({} as FineTuneD);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        if (!fineTuneId) {
          return;
        }

        setLoading(true);
        let res;
        let resEvent;
        if (isJob) {
          res = await getFineTuneJobDetailApi({
            token,
            project_uid: projectUid,
            id: fineTuneId,
          });
          resEvent = await getFineTuneJobEventsApi({
            token,
            project_uid: projectUid,
            fine_tune_id: fineTuneId,
          });
        } else {
          res = await getFineTuneDetailApi({
            token,
            project_uid: projectUid,
            id: fineTuneId,
          });
        }
        setLoading(false);

        if (res.type === "RXSUCCESS") {
          if (isJob) {
            setFineTune({ ...res?.data, events: resEvent?.data?.data });
          } else {
            setFineTune(res?.data);
          }
        }
      } catch (err) {
        setLoading(false);
        logErr("ERR", err);
      }
    };

    if (token && projectUid && fineTuneId) {
      fetchDetail();
    }
  }, [token, projectUid, fineTuneId, isJob]);

  return (
    <div className="bg-gray-50 relative">
      {loading && <FixedOverlayLoader />}
      <div className={appContent()}>
        <div>
          <div className="mb-4 flex items-center gap-2">
            <IconButton
              size="md"
              color="neutral"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FiChevronLeft />
            </IconButton>

            <Typography fontWeight={"md"} color={"neutral"}>
              Fine-tune Details
            </Typography>
          </div>
        </div>
        <div className="mb-4 flex flex-col gap-2">
          <div>
            <Typography level="h4" fontWeight={"md"}>
              {fineTune.fine_tuned_model}
            </Typography>
            <Typography>{fineTune.id}</Typography>
          </div>
          <div>
            <Chip variant="solid" size="sm" color="success" onClick={function () {}}>
              {fineTune.status}
            </Chip>
          </div>
        </div>

        <div className="mb-8 flex gap-4 ">
          <div className="flex-1 rounded-lg bg-white p-6 shadow-sm">
            <div className="mb-2">
              <Typography level="title-lg" fontWeight={"lg"}>
                Details
              </Typography>
              <Typography color="neutral" level="body-md">
                See your fine-tune details below
              </Typography>
            </div>
            {/* LIST START  */}
            <Table
              aria-label="basic table"
              sx={{
                "tr > td:first-child": {
                  fontWeight: "bold",
                },
              }}
            >
              <tbody>
                <tr>
                  <td>Base Model</td>
                  <td>{fineTune.model}</td>
                </tr>
                <tr>
                  <td>Fine-tuned model</td>
                  <td>{fineTune.fine_tuned_model}</td>
                </tr>
                <tr>
                  <td>Created at</td>
                  <td>{formatDateTime(fineTune.created_at)}</td>
                </tr>
                <tr>
                  <td>Updated at</td>
                  <td>{formatDateTime(fineTune.updated_at)}</td>
                </tr>
                <tr>
                  <td>Nr. of epochs</td>
                  <td>{fineTune.hyperparams?.n_epochs}</td>
                </tr>
                <tr>
                  <td>Batch size</td>
                  <td>{fineTune.hyperparams?.batch_size}</td>
                </tr>
                <tr>
                  <td>Prompt loss weight</td>
                  <td>{fineTune.hyperparams?.prompt_loss_weight}</td>
                </tr>
                <tr>
                  <td>Learning rate multiplier</td>
                  <td>{fineTune.hyperparams?.learning_rate_multiplier}</td>
                </tr>
              </tbody>
            </Table>
            {/* LIST END  */}
          </div>

          <div className="flex-1">
            <Events events={fineTune.events || []} />
          </div>
        </div>

        {/* DATASTART  */}
        <Training trainingFiles={fineTune.training_files || []} />
        <Result resultFiles={fineTune.result_files} />
      </div>
    </div>
  );
}
