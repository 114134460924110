import { Button, Checkbox, Chip, CircularProgress, Divider, IconButton, Typography } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { getFileContentApi, getFileDetailApi } from "network/api/project/file";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { ProjectFileItemD } from "types/project";
import { log, logErr } from "utils/helpers";
import { useProject } from "context/ProjectContext";
import { appContent } from "components/variants/app";

export default function FileDetail() {
  const { projectUid } = useProject();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [fileContent, setFileContent] = useState<any>(null);
  const { fileId } = useParams();
  const [fileDetail, setFileDetail] = useState<ProjectFileItemD>({} as ProjectFileItemD);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        if (!fileId) {
          return;
        }

        setLoading(true);
        const res = await getFileDetailApi({
          token,
          file_id: fileId,
          project_uid: projectUid,
        });
        setLoading(false);

        if (res.type === "RXSUCCESS") {
          setFileDetail(res.data);
        }
      } catch (err) {
        setLoading(false);
        logErr("ERR", err);
      }
    };

    if (token && projectUid && fileId) {
      fetchDetail();
    }
  }, [token, projectUid, fileId]);

  //  File Content
  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const res = await getFileContentApi({
          token,
          file_id: fileId,
          project_uid: projectUid,
        });

        log("kkk", res);

        if (res.type === "RXSUCCESS") {
          setFileContent(res.data);
        }
      } catch (err) {
        logErr("Err", err);
      }
    };

    if (token && projectUid && fileId) {
      fetchFileContent();
    }
  }, [token, projectUid, fileId]);

  return (
    <div className="bg-gray-50">
      <div className={appContent()}>
        {loading ? (
          <div className="flex h-24 items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div>
              <div className="mb-4 flex items-center gap-2">
                <IconButton
                  size="md"
                  color="neutral"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FiChevronLeft />
                </IconButton>

                <Typography fontWeight={"md"} color={"neutral"}>
                  File Details
                </Typography>
              </div>
            </div>
            <div className="mb-4 flex flex-col gap-2">
              <div>
                <Typography level="h4" fontWeight={"md"}>
                  {fileDetail?.filename}
                </Typography>
                <Typography>{fileDetail?.id}</Typography>
              </div>
              <div>
                <Chip variant="solid" size="sm" color="primary" onClick={function () {}}>
                  {fileDetail?.status}
                </Chip>
              </div>
            </div>

            <div className="mb-8 flex gap-4 ">
              <div className="flex-1 rounded-lg bg-white p-6 shadow-sm">
                {/* LIST START  */}
                <div className=" flex flex-col gap-2">
                  <div className="flex justify-between gap-2">
                    <Typography fontWeight={"md"}>Purpose : </Typography>
                    <Typography fontWeight={"sm"}>{fileDetail?.purpose}</Typography>
                  </div>
                  <Divider />
                  <div className="flex justify-between gap-2">
                    <Typography fontWeight={"md"}>Bytes : </Typography>
                    <Typography fontWeight={"sm"}>{fileDetail?.bytes}</Typography>
                  </div>
                  <Divider />
                  <div className="flex justify-between gap-2">
                    <Typography fontWeight={"md"}>Object : </Typography>
                    <Typography fontWeight={"sm"}>{fileDetail?.object}</Typography>
                  </div>
                  <Divider />
                  <div className="flex justify-between gap-2">
                    <Typography fontWeight={"md"}>Created at</Typography>
                    <Typography fontWeight={"sm"}>{new Date(fileDetail?.created_at * 1000).toLocaleString()}</Typography>
                  </div>
                </div>

                {/* LIST END  */}
              </div>

              <div className="flex flex-1 flex-col rounded-lg bg-white p-6 shadow-sm">
                <Typography level="body-lg" fontWeight={"lg"}>
                  Download File
                </Typography>

                <div className="mt-4 mb-3 flex gap-8">
                  <Checkbox label="CSV" size="md" defaultChecked />

                  <Checkbox label="JSON" size="md" defaultChecked />
                </div>

                <Button size="md" sx={{ marginTop: "auto" }}>
                  Download
                </Button>
              </div>
            </div>

            {/* DATASTART  */}

            {/* <Data /> */}

            <div className="bg-white p-4 shadow-sm">
              <div className="mb-4">
                <Typography level="title-lg">Raw JSON</Typography>
              </div>

              <div className="whitespace-pre-wrap text-sm text-gray-500 ">{typeof fileContent === "string" && fileContent}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
