import { format } from "date-fns";

export const log = (...args: any[]) => {
  // return console.log(...args);
};
export const logErr = (...args: any[]) => {
  return console.error(...args);
};

export const formatDateTime = (tm: number | string | undefined | null) => {
  if (!tm) return "";
  if (typeof tm === "number") {
    return format(new Date(tm * 1000), "dd, MMM yyyy, HH:MM");

    // return new Date(tm * 1000).toLocaleString();
  } else if (tm) {
    return format(new Date(tm), "dd, MMM yyyy, HH:MM");
    // return new Date(tm).toLocaleString();
  }
};

export function isValidURL(str: string): boolean {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(str);
}
export function isValidFileType(str: string): boolean {
  const regex = /\.txt$|\.pdf$|\.doc$|\.docx$|\.ppt$|\.csv$/i;
  return regex.test(str);
}

export function timeAgo(lastMessageTimestamp: number) {
  const date1 = new Date();
  const date2 = new Date(Number(lastMessageTimestamp));
  const timeDifferenceMs = date1.getTime() - date2.getTime();
  const seconds = Math.floor(timeDifferenceMs / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hr" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " min" + (interval === 1 ? "" : "s") + " ago";
  }
  return "Just now";
}
