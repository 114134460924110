import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectItemD } from "types/project";
import { formatDateTime, timeAgo } from "utils/helpers";
import { IoSave } from "react-icons/io5";
import { RiCloseFill } from "react-icons/ri";
import { deleteProjectApi, updateProjectApi } from "network/api/project";
import { useAuth } from "context/AuthContext";
import { toast } from "react-hot-toast";
import { ApiResType } from "types/enum";
import { IoIosAddCircle } from "react-icons/io";
import { Tooltip } from "@mui/joy";
import ConfirmModal from "components/ConfirmModal";
import { CgPen } from "react-icons/cg";
import { ImBin2 } from "react-icons/im";

export default function Card({ i, onDelete }: { i: ProjectItemD; onDelete: (id: string) => any }) {
  const { token } = useAuth();
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(i?.name);
  const navigate = useNavigate();
  const inputRef = useRef<any>();
  const [deleteId, setDeleteId] = useState("");
  const [deleting, setDeleting] = useState(false);

  // FUNCTIONS
  const onClick = () => {
    if (!editing) {
      navigate(`/projects/${i?.project_uid}`);
    }
  };

  const onUpdate = async () => {
    if (!name.trim()) {
      return;
    }

    const updatePro: any = async () => {
      try {
        setEditing(false);
        const res = await updateProjectApi({ token, project_uid: i?.project_uid, name });
        if (res.type === ApiResType.SUCCESS) {
          return "success";
        } else {
          throw res.message;
        }
      } catch (err) {
        console.error("Err", err);
        setName(i?.name);
      }
    };

    toast.promise(updatePro(), {
      loading: "Updating",
      success: "Updated",
      error: "Error: not updated",
    });
  };

  const deleteProject = async () => {
    try {
      setDeleting(true);
      const res = await deleteProjectApi({ project_uid: deleteId, token });
      setDeleting(false);

      if (res.type === ApiResType.SUCCESS) {
        toast.success("Project deleted successfully");
        onDelete(deleteId);
        setDeleteId("");
      }
      if (res.type === ApiResType.ERROR) {
        toast.error(res?.message || "Something went wrong!");
      }
    } catch (err) {
      setDeleting(false);
      console.error("Err", err);
    }
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [editing]);

  return (
    <>
      <Tooltip title={formatDateTime(i?.createdAt)}>
        <div className="border border-gray-200 rounded-xl p-4 group relative hover:border-primary  transition-all group">
          <div className=" flex absolute top-[10px] right-[10px] z-[3] opacity-0 group-hover:opacity-[1]  transition-all">
            <button
              className="text-gray-400 hover:text-primary p-1"
              onClick={() => {
                setEditing((s) => !s);
                if (editing) {
                  setName(i?.name);
                }
              }}
            >
              {editing ? <RiCloseFill size={24} /> : <CgPen height={22} />}
            </button>
            <button className="text-red-400 hover:text-red-600 p-1" onClick={() => setDeleteId(i?.project_uid?.toString())}>
              <ImBin2 height={22} />
            </button>
          </div>
          <div onClick={onClick} className="cursor-pointer">
            <div className="mb-3">{/* <Logo className="text-primary" height={42} />  */}</div>

            <div className="flex items-center gap-1 mb-4">
              {editing ? (
                <div className="flex gap-1 items-center">
                  <input
                    ref={inputRef}
                    className="font-semibold w-full bg-gray-100 rounded-md px-2 flex-1 outline-none border border-gray-100"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onUpdate();
                      }
                    }}
                  />
                  <button onClick={onUpdate}>
                    <IoSave size={22} className="text-gray-400 hover:text-primary" />
                  </button>
                </div>
              ) : (
                <h4 className="font-semibold w-full overflow-hidden text-ellipsis  py-[1px]">{name}</h4>
              )}
            </div>

            <div className="text-sm text-gray-400">
              Last updated: <span className="">{timeAgo(new Date(i?.createdAt).getTime())}</span>
            </div>
          </div>
        </div>
      </Tooltip>

      <ConfirmModal danger onClose={() => setDeleteId("")} open={deleteId ? true : false} onConfirm={deleteProject} loading={deleting} confirmTitle="Yes delete" title="Delete this project" />
    </>
  );
}
