import { useAuth } from 'context/AuthContext';
import { getSessionMessagesBySessionIdApi } from 'network/api/project/dashboard';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { log, logErr } from 'utils/helpers';
import styled from 'styled-components';
import { appContent } from 'components/variants/app';

export default function SessionDetail() {
  const { sessionId } = useParams();
  const {token} = useAuth();
  const [data, setData] = useState([] as {id:number, message: string, send_by: 'assistant' | 'user'}[]);

  // SIDE EFFECTS
  useEffect(() => {
    if(!token || !sessionId) return;

    const getDetail = async () => {
      try {
        const raw = {
          session_uid: sessionId,
          // page: 1,
          // limit: 20,
          // orderBy: 'desc'
        }
        const res = await getSessionMessagesBySessionIdApi({token, raw});
        setData(res?.data); 
      } catch (err) {
        logErr('Err', err);
      }
    };

    getDetail();
  }, [token, sessionId]);

  log('data', data);
  
  return (
    <div className={appContent({ place: "console" })}>
      <h2 className='text-2xl font-bold'>Session Details</h2>
      <ul>
      {
        data?.map(i => {
          if(i?.send_by === 'assistant') {
            return <AssistantMsg className=''>{i?.message}</AssistantMsg>;
          } else {
            return <UserMsg>{i?.message}</UserMsg>;
          }
        })
      }
      </ul>
    </div>
  )
};

const AssistantMsg = styled.li`
  border: 1px solid #eee;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  width: 50%;
  margin: 10px 0;
  background-color: #eeeeee82;
`;
const UserMsg = styled.li`
  border: 1px solid #eee;
  border-radius: 10px 10px 0px 10px;
  padding: 10px;
  background-color: #2563EB;
  color: #fff;
  width: 30%;
  margin-left: auto;
`;