export type MemberD = {
  id: number;
  user_id: number;
  project_id: number;
  deleted_at: string;
  role: MemberRole;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  user: {
    id: string;
    name: string;
    email: string;
    username: string;
    profile_pic: string;
  };
};

export enum MemberRole {
  Owner = "owner",
  Editor = "editor",
  Viewer = "viewer",
}
