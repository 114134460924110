import { Button } from "@mui/joy";
import { useState } from "react";
import { IntegrationId } from "types/project";
import styled from "@emotion/styled";
import IntegrationModal from "./Modal";

export default function IntegrationCard({ item }: { item: { id: IntegrationId; name: string; icon: any; description: string; details: string; color: string; type: string } }) {
  const [open, setOpen] = useState(false);

  return (
    <Root>
      <div className="flex gap-2 items-center">
        <div className="rounded h-10 aspect-square flex items-center justify-center text-white text-xl" style={{ background: item?.color }}>
          {item?.icon}
        </div>
        <h3 className="font-semibold text-lg">{item?.name}</h3>
      </div>

      <div>{item?.description}</div>

      <div className="flex justify-between items-center">
        <Button variant="soft" size="sm" color="neutral" sx={{ marginLeft: "auto" }} onClick={() => setOpen(true)}>
          Learn more
        </Button>
      </div>

      <IntegrationModal open={open} onClose={() => setOpen(false)} type={item?.type} />
    </Root>
  );
}

const Root = styled.div`
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: 1px solid #eee;
  background-color: #fafafa;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  &:hover {
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  img {
    height: 40px;
  }
`;
