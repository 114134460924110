import { CircularProgress } from "@mui/joy";
import React from "react";

export default function OverlayLoader() {
  return (
    <div className="bg-white/80 z-30 absolute h-full w-full flex items-center justify-center">
      <CircularProgress size="sm" />
    </div>
  );
}
export function FixedOverlayLoader() {
  return (
    <div className="bg-white/80 z-30 fixed h-full w-full flex items-center justify-center">
      <CircularProgress size="sm" />
    </div>
  );
}
