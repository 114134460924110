import { ModalClose } from "@mui/joy";

export default function Zapier() {
  return (
    <div className="flex flex-col md:flex-row gap-5">
      <div>
        <div className="text-xl font-bold">Zapier Integration</div>
        <div className="text-black/70 text-sm">Integrate with Zapier's technology seamlessly.</div>

        <p className="mt-5 text-[15px]">
          With this integration, you can effortlessly connect our tool to a wide range of apps and services, enabling automated workflows that suit your specific needs. Streamline your processes, save time, and increase productivity by
          easily triggering actions and sharing data between applications. Experience the convenience of automated fine-tuning with the click of a button, making your tasks more efficient and effective than ever before.
        </p>
      </div>

      <img src={require("../../../../../assets/images/zapier.png")} alt="zapier integration" className="h-[520px] object-contain" />

      <ModalClose variant="outlined" sx={{ background: "#fff" }} />
    </div>
  );
}
