import { Box, Button, Chip, CircularProgress, Divider, FormControl, FormLabel, IconButton, Modal, ModalDialog, Option, Select, selectClasses, Sheet, Table, Typography } from "@mui/joy";
import React, { useMemo, useState } from "react";
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiDelete, FiPlus } from "react-icons/fi";
import { BsPlus } from "react-icons/bs";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { IoWarning } from "react-icons/io5";
import { useListingApi } from "hooks/useListingApi";
import { SortD } from "types";
import { formatDateTime, logErr } from "utils/helpers";
import DataTable from "react-data-table-component";
import { deleteFileApi, deleteProjectFileApi } from "network/api/project/file";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { useLocation, useNavigate } from "react-router-dom";
import { appContent } from "components/variants/app";
import ConfirmModal from "components/ConfirmModal";

type ProjectFileItemD = {
  createdAt: number;
  name: string;
  id: string;
  status: string;
};

export default function List() {
  const navigate = useNavigate();
  // const pathname = usePathname();

  const { pathname } = useLocation();

  const [deleteId, setDeleteId] = useState("");

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortD>("desc");
  const { token } = useAuth();

  const { projectUid } = useProject();

  const {
    data,
    total,
    apiError,
    loading,
    setData,
    setTotal,
  }: {
    data: ProjectFileItemD[];
    total: number;
    apiError: string;
    loading: boolean;
    setData: React.Dispatch<React.SetStateAction<ProjectFileItemD[]>>;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
  } = useListingApi({
    limit,
    page,
    search,
    sort,
    type: "projectFiles",
    project_uid: projectUid,
  });

  const [deleting, setDeleting] = useState(false);

  const COLS: {
    name: string;
    wrap: boolean;
    selector: (d: ProjectFileItemD) => any;
  }[] = useMemo(
    () => [
      {
        name: "ID",
        wrap: true,
        selector: (i) => i?.id,
        width: "20%",
      },
      {
        name: "Filename",
        wrap: true,
        selector: (i) => i?.name,
      },
      {
        name: "Created date",
        wrap: true,
        selector: (i) => formatDateTime(i?.createdAt),
      },
      {
        name: "Status",
        wrap: true,
        selector: (i) => (
          <div className="capitalize">
            <Chip variant="solid" size="sm" color={i?.status === "error" ? "danger" : i?.status === "success" ? "success" : "primary"}>
              {!i?.status ? "Pending" : i?.status}
            </Chip>
          </div>
        ),
      },
      {
        name: "Actions",
        wrap: true,
        width: "20%",
        selector: (i) => (
          <>
            <div className="flex gap-1">
              <IconButton variant="outlined" color="danger" onClick={() => setDeleteId(i?.id?.toString())}>
                <AiFillDelete />
              </IconButton>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const onDelete = async () => {
    try {
      setDeleting(true);

      const res = await deleteProjectFileApi({
        id: deleteId,
        project_uid: projectUid,
        token,
      });
      setDeleting(false);

      if (res.type === "RXSUCCESS") {
        setDeleteId("");
        setData((s) => s.filter((s) => s?.id?.toString() !== deleteId));
        setTotal((s) => s - 1);
      }
    } catch (err) {
      setDeleting(false);
      logErr("Err", err);
    }
  };

  return (
    <div className={appContent({ place: "train" })}>
      <div className="flex justify-between">
        <div className="right">
          <Typography level="title-lg">Your Files</Typography>
          <Typography level="body-md">{data.length} results</Typography>
        </div>

        <div className="right flex items-center gap-2">
          <Button size="md" startDecorator={<FiPlus />} onClick={() => navigate(pathname + "/add")}>
            Add new
          </Button>
        </div>
      </div>
      {/* HEADER END  */}

      <div>
        <Sheet>
          {loading ? (
            <div className="flex h-20 items-center justify-center">
              <CircularProgress size="md" />
            </div>
          ) : (
            <DataTable columns={COLS} data={data} pagination paginationRowsPerPageOptions={[10, 50, 100]} paginationPerPage={10} />
          )}
        </Sheet>
      </div>

      <ConfirmModal open={deleteId ? true : false} onClose={() => setDeleteId("")} danger confirmTitle="Delete file" loading={deleting} onConfirm={() => onDelete()} desc="This action is irreversible" title="Delete this file" />
    </div>
  );
}
