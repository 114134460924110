import ProjectLayout from "components/layouts/ProjectLayout";
import RootLayout from "components/layouts/RootLayout";
import { TrainNabar } from "components/Navbar";
import AuthProvider from "context/AuthContext";
import ProjectProvider from "context/ProjectContext";
import ProjectFiledAdd from "pages/console/project/file/add";
import FileDetail from "pages/console/project/file/detail";
import ProjectFineTunesAdd from "pages/console/project/fineTune/add";
import FileTuneDetail from "pages/console/project/fineTune/detail";
import ProjectFineTunes from "pages/console/project/fineTune/list";
import DiscordIntegrationStatus from "pages/console/project/integration/discordStatus";
import ProjectPlayground from "pages/console/project/playground";
import ProjectSettings from "pages/console/project/settings";
import ProjectSettingGeneral from "pages/console/project/settings/general";
import ProjectSettingKeys from "pages/console/project/settings/keys";
import ProjectSettingMembers from "pages/console/project/settings/members";
import { Provider } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import { store } from "store";
import SlackIntegrationStatus from "pages/console/project/integration/slackStatus";
import ProjectDashboard from "pages/console/project/dashboard";
import SessionDetail from "pages/console/project/dashboard/SessionDetail";
import Sitemap from "pages/console/project/sitemap";
import Pages from "pages/console/project/pages";
import PaymentSuccess from "pages/payment-success";
import PaymentError from "pages/payment-error";
import ProjectFiles from "pages/console/project/file/list";
import AddOpenAIKey from "pages/console/project/settings/addKey";
import Datasets from "pages/console/project/datasets";
import Projects from "pages/console/project/list";
import CreateDataset from "pages/console/project/datasets/CreateDataset";
import Upgrade from "pages/upgrade";
import IntegrationList from "pages/console/project/integration/IntegrationV2/List";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Provider store={store}>
          <AuthProvider>
            <ProjectProvider>
              <Outlet />
            </ProjectProvider>
          </AuthProvider>
        </Provider>
      }
    >
      <Route path="/">
        {/* EXTERNALS  */}
        <Route path="/integration/discord/status" element={<DiscordIntegrationStatus />} />
        <Route path="/integration/slack/status" element={<SlackIntegrationStatus />} />

        <Route element={<RootLayout />}>
          <Route path="" element={<Navigate to={"/projects"} />} />
          <Route path="/upgrade" element={<Upgrade />} />
          <Route path="/projects" element={<Projects />} />
        </Route>

        <Route path="/projects/:projectUid" element={<ProjectLayout />}>
          <Route path="" element={<Navigate relative="path" to="train/files" />} />
          <Route
            path="overview"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route path="" element={<ProjectDashboard />} />
            <Route path="session/:sessionId" element={<SessionDetail />} />
          </Route>
          <Route path="playground" element={<ProjectPlayground />} />
          <Route
            path="train"
            element={
              <>
                <TrainNabar />
                <Outlet />
              </>
            }
          >
            <Route path="" element={<ProjectFiles />} />
            <Route path="files" element={<ProjectFiles />} />
            <Route path="files/:fileId" element={<FileDetail />} />
            <Route path="files/add" element={<ProjectFiledAdd />} />
            <Route path="fine-tunes" element={<ProjectFineTunes />} />
            <Route path="fine-tunes/:fineTuneId" element={<FileTuneDetail />} />
            <Route path="fine-tunes-jobs/:fineTuneId" element={<FileTuneDetail isJob={true} />} />
            <Route path="fine-tunes/add" element={<ProjectFineTunesAdd />} />
            <Route path="sitemap" element={<Sitemap />} />
            <Route path="pages" element={<Pages />} />
          </Route>
          <Route path="datasets">
            <Route path="" element={<Datasets />} />
            <Route path="create" element={<CreateDataset />} />
          </Route>
          <Route path="integration" element={<IntegrationList />} />

          {/* SETTINGS  */}
          <Route path="settings" element={<ProjectSettings />}>
            <Route path="" element={<Navigate replace relative="path" to="members" />} />
            {/* <Route path="general" element={<ProjectSettingGeneral />} />
            <Route path="keys" element={<ProjectSettingKeys />} /> */}
            <Route path="members" element={<ProjectSettingMembers />} />
            <Route path="openAiKey" element={<AddOpenAIKey />} />
          </Route>
        </Route>
      </Route>

      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-error" element={<PaymentError />} />
    </Route>
  )
);
