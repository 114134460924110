import { Button } from "@mui/joy";
import { useProject } from "context/ProjectContext";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export function MissingKeyBox({ id }: { id: any }) {
  return (
    <div>
      <div className="text-red-400 text-sm font-semibold">
        Missing OpenAI key <br />
        <Link className="text-blue-500 underline" to={`/projects/${id}/settings/openAiKey`}>
          Add now
        </Link>
      </div>
    </div>
  );
}
export function MissingKeyOverlay() {
  const { projectUid } = useProject();

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="bg-black/90 flex items-center justify-center absolute left-0  top-0 w-full h-full z-20">
      <div className="text-white flex flex-col items-center justify-center">
        <h5 className="font-semibold text-lg mb-1">Project missing OpenAI key</h5>
        <p className="mb-4 opacity-60">Add OpenAI key to access projects features</p>
        <Link to={`/projects/${projectUid}/settings/openAiKey`}>
          <Button>Add OpenAI key to project</Button>
        </Link>
      </div>
    </div>
  );
}
