import { Button } from '@mui/joy'; 
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';  

export default function PaymentError() { 
    const navigate = useNavigate();

    return (
        <Root>
            <Card>
                <Tick>
                    <BsFillExclamationCircleFill />
                </Tick>

                <div className='font-bold text-2xl border-b border-dashed w-full text-center pb-6'>Error Occurred!</div> 

                <div className='text-center max-w-[300px]'>
                    {/* An unexpected error occurred while processing your payment. If any amount was deducted it will be refunded with 5-7 business days. */}
                    We apologize, but there was an issue processing your payment. Please ensure that all payment information is accurate and that you have sufficient funds available in your account. If the issue persists, please contact us at support@yourgpt.ai for assistance.
                </div>

                <Button color="danger" variant='outlined' onClick={() => navigate('/projects')}>Go Back</Button>
            </Card>
        </Root>
    )
};

const Root = styled.div`
    display: grid;
    height: 100vh;
    place-content: center; 
`;
const Card = styled.div`
    padding: 2em;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    min-width: 350px;
    min-height: 400px;
    font-size: 14px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 80%;
        width: 100%;
        font-size: 14px;
        padding: 0.5em 0;
    
        span:last-child {
            font-weight: 700;
            opacity: 100%;
        }
    }
`;
const Tick = styled.div`
    color: #a10e25;
    font-size: 36px; 
    border-radius: 100%;
    width: 60px;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    background-color: rgba(161, 14, 37, 0.2);
`;
// const Button = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 0.5em;
// `;