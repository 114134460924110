import { Alert, Button, Typography } from "@mui/joy";
import ModelSelect from "components/ModelSelect";
import { appContent } from "components/variants/app";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { createDatasetWithAIApi } from "network/api/project/datasets";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaDownload } from "react-icons/fa";
import { ApiResType } from "types/enum";
import { ModelItemD } from "types/model";

const DEFAULT_PURPOSE = `Assume the character of Emma, a customer service executive for ALBA CARS, a used car dealership in Dubai located in Al Qouz.

Be friendly and human-like in interactions including the use of emojis where appropriate.

Be sympathetic and kind, but also an efficient salesperson.
Maintain answers that are concise - a few sentences at most.

Always aim to steer conversations towards booking a showroom visit. If they do not want to visit only then offer connecting the customer with a sales expert.`;

export default function CreateDataset() {
  const { token } = useAuth();
  const { projectUid } = useProject();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [purpose, setPurpose] = useState(DEFAULT_PURPOSE);
  const [model, setModel] = useState<ModelItemD>({
    id: "gpt-3.5-turbo",
  } as ModelItemD);
  const [generatedData, setGeneratedData] = useState("");
  const [hideCreate, setHideCreate] = useState(false);

  const onCreate = async () => {
    if (!token) return;
    if (!purpose) return setError("Purpose cannot be blank");

    try {
      setLoading(true);
      const res = await createDatasetWithAIApi({ token, purpose, project_uid: projectUid, model: model?.id });
      if (res?.type === ApiResType.SUCCESS) {
        toast.success("Dataset created successfully");
        setGeneratedData(res?.data?.message);
        setHideCreate(true);
      }
      if (res?.type === ApiResType.ERROR) {
        setError(res.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const downloadFile = () => {
    if (generatedData) {
      const blob = new Blob([generatedData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tuneup_dataset.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <div className={appContent({ place: "train" })}>
      {!hideCreate && (
        <div className="mb-5">
          <header className="mb-5">
            <Typography id="alert-dialog-modal-title" component="h2" fontWeight={"lg"}>
              Create an AI Generated Dataset
            </Typography>
            <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
              Develop comprehensive AI dataset to elevate model capabilities.
            </Typography>
            {/* <Divider /> */}
          </header>

          <div className="flex flex-col gap-1 mb-3">
            <label htmlFor="purpose" className="text-sm font-semibold text-black/70">
              Choose Model
            </label>
            <ModelSelect
              type="dataset"
              name="model"
              value={model}
              onChange={(e) => {
                setModel(e);
              }}
              autoSet={false}
            />
          </div>

          <div className="flex flex-col gap-1 mb-3">
            <div className="flex items-center gap-2">
              <label htmlFor="purpose" className="text-sm font-semibold text-black/70">
                Add Purpose
              </label>
            </div>
            <textarea id="purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)} placeholder="Write the purpose of the dataset here" className="border rounded-lg min-h-[200px] p-2 text-sm" />
          </div>

          {error && (
            <Alert size="sm" sx={{ marginTop: 1 }} variant="soft" color="danger" className="mb-2">
              {error}
            </Alert>
          )}

          <Button variant="solid" loading={loading} onClick={onCreate}>
            Create
          </Button>
        </div>
      )}

      {generatedData?.length > 0 && typeof generatedData === "string" && (
        <>
          <div className="flex gap-1 justify-end mb-3">
            <Button variant="soft" onClick={() => setHideCreate(false)}>
              Edit Purpose
            </Button>
            <Button variant="solid" onClick={downloadFile} startDecorator={<FaDownload />}>
              Download
            </Button>
          </div>

          <textarea className="border w-full min-h-[400px] p-2 rounded-lg" value={generatedData} onChange={(e) => setGeneratedData(e.target.value)} />
        </>
      )}
    </div>
  );
}
