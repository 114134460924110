import { Box, Button, Chip, CircularProgress, Divider, FormControl, FormLabel, IconButton, Modal, ModalDialog, Option, Select, selectClasses, Sheet, Table, Typography } from "@mui/joy";
import React, { useMemo, useState } from "react";
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiDelete, FiPlus } from "react-icons/fi";
import { BsPlus } from "react-icons/bs";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { IoWarning } from "react-icons/io5";
import { useListingApi } from "hooks/useListingApi";
import { SortD } from "types";
import { ProjectFileItemD } from "types/project";
import { formatDateTime, logErr } from "utils/helpers";
import DataTable from "react-data-table-component";
import { deleteFileApi } from "network/api/project/file";
import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { useLocation, useNavigate } from "react-router-dom";
import { appContent } from "components/variants/app";
import ConfirmModal from "components/ConfirmModal";

export default function FineTuneList() {
  const navigate = useNavigate();
  // const pathname = usePathname();

  const { pathname } = useLocation();

  const [deleteId, setDeleteId] = useState("");

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<SortD>("desc");
  const { token } = useAuth();

  const { projectUid } = useProject();

  const {
    data,
    total,
    apiError,
    loading,
    setData,
    setTotal,
  }: {
    data: ProjectFileItemD[];
    total: number;
    apiError: string;
    loading: boolean;
    setData: React.Dispatch<React.SetStateAction<ProjectFileItemD[]>>;
    setTotal: React.Dispatch<React.SetStateAction<number>>;
  } = useListingApi({
    limit,
    page,
    search,
    sort,
    type: "fineTuneFiles",
    project_uid: projectUid,
  });

  const [deleting, setDeleting] = useState(false);

  const COLS: {
    name: string;
    wrap: boolean;
    selector: (d: ProjectFileItemD) => any;
  }[] = useMemo(
    () => [
      {
        name: "Filename",
        wrap: true,
        selector: (i) => i.filename,
      },
      {
        name: "ID",
        wrap: true,
        width: "25%",
        selector: (i) => i.id,
      },
      {
        name: "Created date",
        wrap: true,
        selector: (i) => formatDateTime(i.created_at),
      },
      {
        name: "Purpose",
        wrap: true,
        selector: (i) => i.purpose,
      },
      {
        name: "Status",
        wrap: true,
        selector: (i) => (
          <Chip size="sm" variant="solid" className="capitalize" color={i?.status === "error" ? "danger" : i?.status === "processed" ? "success" : "primary"}>
            {i.status}
          </Chip>
        ),
      },
      {
        name: "Actions",
        wrap: true,
        selector: (i) => (
          <>
            <div className="flex gap-1">
              <IconButton variant="outlined" color="danger" onClick={() => setDeleteId(i.id)}>
                <AiFillDelete />
              </IconButton>
              <IconButton
                variant="solid"
                color="primary"
                onClick={() => {
                  navigate(pathname + "/" + i.id);
                }}
              >
                <AiFillEye />
              </IconButton>
            </div>
          </>
        ),
      },
    ],
    [pathname, navigate]
  );

  const onDelete = async () => {
    try {
      setDeleting(true);

      const res = await deleteFileApi({
        file_id: deleteId,
        project_uid: projectUid,
        token,
      });
      setDeleting(false);

      if (res.type === "RXSUCCESS") {
        setDeleteId("");
        setData((s) => s.filter((s) => s.id !== deleteId));
        setTotal((s) => s - 1);
      }
    } catch (err) {
      setDeleting(false);
      logErr("Err", err);
    }
  };

  return (
    <div className={appContent({ place: "train" })}>
      <div className="flex justify-between">
        <div className="right">
          <Typography level="title-lg">Your OpenAI files</Typography>
          <Typography level="body-md">{data.length} results</Typography>
        </div>

        <div className="right flex items-center gap-2">
          <Button size="md" startDecorator={<FiPlus />} onClick={() => navigate(pathname + "/add")}>
            Add new
          </Button>
        </div>
      </div>
      {/* HEADER END  */}

      <div>
        <Sheet>
          {loading ? (
            <div className="flex h-20 items-center justify-center">
              <CircularProgress size="md" />
            </div>
          ) : (
            <DataTable columns={COLS} data={data} pagination paginationRowsPerPageOptions={[10, 50, 100]} paginationPerPage={10} />
          )}
        </Sheet>
      </div>

      <ConfirmModal
        open={deleteId ? true : false}
        onClose={() => setDeleteId("")}
        danger
        confirmTitle="Delete file"
        loading={deleting}
        onConfirm={() => onDelete()}
        desc="This action in not reversible"
        // title='Delete this file '
      />
    </div>
  );
}
