import { useProject } from "context/ProjectContext";
import FineTunePg from "./FineTunePg";
import { Purpose } from "types/enum";
import OtherPurposePg from "./OtherPurposePg";

export default function ProjectPlayground() {
  const { activeProject } = useProject();

  return <>{activeProject?.purpose === Purpose.fineTuning ? <FineTunePg /> : <OtherPurposePg />}</>;
}
