import { Button, CircularProgress, Input, Option, Select, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ProjectItemD } from "types/project";
import { SortD } from "types";
import { ListingApiKeys, useListingApi } from "hooks/useListingApi";
import { appContent } from "components/variants/app";
import ProjectCreateModal from "components/project/ProjectCreateModal";
import { useAppDispatch } from "store";
import { setProjects } from "store/projectsSlice";
import { useAuth } from "context/AuthContext";
import Card from "./Card";
import Skel from "./Skel";

export default function Projects() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState<SortD>("asc");
  const [search, setSearch] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const { data: list, loading, total, setData }: ListingApiKeys<ProjectItemD> = useListingApi({ type: "project", limit, page, search, sort });
  const { token, metaInfo } = useAuth();
  const dispatch = useAppDispatch();

  // FUNCTIONS
  const onDelete = (deleteId: string) => {
    setData((s) => s.filter((i) => i.project_uid.toString() !== deleteId));
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (page === 1 && list.length > 0) {
      dispatch(setProjects(list));
    }
  }, [list, page, dispatch]);

  useEffect(() => {
    if (token && metaInfo) {
      setCreateOpen(true);
    }
  }, [token, metaInfo]);

  return (
    <div className={appContent({ place: "console" })}>
      <div className="">
        <div>
          <div className="mb-6 flex items-center justify-between">
            <Typography level="title-lg" fontWeight={"md"}>
              Your projects
            </Typography>

            <div className="">
              <Button variant="outlined" startDecorator={<FaPlus />} onClick={() => setCreateOpen(true)}>
                Add Project
              </Button>
            </div>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <div>
              <Input type={"search"} size="sm" placeholder="Search project" onChange={(e) => setSearch(e.target.value)} value={search} />
            </div>

            <div>
              <Select
                size="sm"
                placeholder="Sort"
                value={sort}
                onChange={(_, val) => {
                  if (val) {
                    setSort(val);
                  }
                }}
              >
                <Option value={"asc"}>Latest</Option>
                <Option value={"desc"}>Oldest</Option>
              </Select>
            </div>
          </div>

          {!loading && list.length === 0 ? (
            <>
              {search ? (
                <>
                  <div className="flex  flex-col items-center justify-center gap-4 rounded-lg bg-gray-100 py-16">
                    <Typography textColor="neutral.500">No Project found</Typography>
                  </div>
                </>
              ) : (
                <div className="flex  flex-col items-center justify-center gap-4 rounded-lg bg-gray-100 py-16">
                  <Typography textColor="neutral.500">You do not have any project</Typography>

                  <Button
                    onClick={() => {
                      setCreateOpen(true);
                    }}
                    variant="outlined"
                    startDecorator={<FaPlus />}
                  >
                    Create new project
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-3">
                {loading && Array.from({ length: 5 }, (_, i) => i + 1).map((i) => <Skel />)}
                {list?.map((i, indx) => (
                  <Card key={indx} i={i} onDelete={onDelete} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <ProjectCreateModal
        open={createOpen}
        onCreate={(e) => {
          setData((s) => [...s, e]);
        }}
        onClose={() => setCreateOpen(false)}
      />
    </div>
  );
}
