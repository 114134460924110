import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import ProjectsDropdown from "components/project/ProjectsDropdown";
import { appContent } from "components/variants/app";
import { useAuth } from "context/AuthContext";
import { useMemo } from "react";
import { FaUserCircle } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { THEME } from "utils/constants/ui";
import { log } from "utils/helpers";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { useProject } from "context/ProjectContext";
import { Purpose } from "types/enum";
import { RiVipCrownFill } from "react-icons/ri";
import styled from "styled-components";

const ROOT_LINKS = [
  {
    link: "/projects",
    label: "Projects",
  },
  // {
  //   link: "/organizations",
  //   label: "Organizations",
  // },
];

export default function Navbar({ project = false }) {
  const { projectUid } = useParams();
  const location = useLocation();
  const { logout } = useAuth();
  let LINKS = ROOT_LINKS;

  const PROJECT_LINKS = useMemo(() => {
    return [
      // {
      //   id: 1,
      //   link: `/projects/${projectUid}/overview`,
      //   label: "Overview",
      // },
      {
        id: 2,
        link: `/projects/${projectUid}/playground`,
        label: "Playground",
      },
      {
        id: 1,
        link: `/projects/${projectUid}/train/files`,
        label: "Train",
      },
      {
        id: 3,
        link: `/projects/${projectUid}/datasets`,
        label: "Datasets",
      },
      {
        id: 4,
        link: `/projects/${projectUid}/integration`,
        label: "Integration",
      },
      {
        id: 5,
        link: `/projects/${projectUid}/settings`,
        label: "Settings",
      },
    ];
  }, [projectUid]);

  if (project) {
    LINKS = [...PROJECT_LINKS];
  } else {
    LINKS = [...ROOT_LINKS];
  }

  return (
    <Root>
      <div className={`${project ? "bg-blue-600" : "bg-white"} sticky top-0 z-20`}>
        <div
          className={appContent({
            class: "shadow-sm py-0",
          })}
        >
          <div className="relative flex items-center justify-center" style={{ height: THEME.navbarHeight }}>
            <div className="flex gap-2  h-full items-center absolute left-0">
              <Link to="/projects">
                <Logo className={`${project ? "fill-white" : "fill-black"} h-9 `} />
              </Link>

              {project && (
                <div className="ml-4 self-stretch">
                  <ProjectsDropdown />
                </div>
              )}
            </div>

            <div className="gap-6 hidden md:flex">
              {LINKS.map((i) => {
                log(i.link, location.pathname);

                return (
                  <NavLink key={i.label} to={i.link} className={({ isActive }) => `${location.pathname.includes(i.link) ? (project ? "text-white" : "text-black") : project ? "text-white/50" : "text-black/40"} font-medium `}>
                    {i.label}
                  </NavLink>
                );
              })}
            </div>

            <div className="absolute right-0 h-full flex gap-1 cursor-pointer items-center hover:bg-black/10 transition-all">
              <Dropdown>
                <MenuButton className="menuButton" variant="plain" size="lg" sx={{ color: project ? "#fff" : "rgba(0,0,0,0.7)" }}>
                  <div className="items-center hidden md:flex">
                    <div className="flex gap-1 items-center">
                      <div>
                        <FaUserCircle />
                      </div>
                      <div>Account</div>
                    </div>

                    <FiChevronDown />
                  </div>
                  <GiHamburgerMenu className="md:hidden text-2xl" />
                </MenuButton>

                <Menu
                  sx={{
                    minWidth: 180,
                  }}
                >
                  <div>
                    <div className="md:hidden">
                      {LINKS.map((i, index) => {
                        return (
                          <div key={index}>
                            <NavLink key={i.label} to={i.link} className={({ isActive }) => `${location.pathname.includes(i.link) ? (project ? "text-white" : "text-black") : project ? "text-white/40" : "text-black/40"} font-medium `}>
                              <MenuItem
                                sx={{
                                  ":focus-visible": {
                                    outline: "none",
                                  },
                                }}
                              >
                                {i.label}
                              </MenuItem>
                            </NavLink>
                          </div>
                        );
                      })}
                    </div>
                    <MenuItem component={Link} to="/upgrade">
                      <div className="flex items-center gap-2">
                        <span>Upgrade</span>
                        <RiVipCrownFill className="text-lg text-black/70" />
                      </div>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        ":focus-visible": {
                          outline: "none",
                        },
                      }}
                      color="danger"
                      onClick={logout}
                    >
                      Logout
                    </MenuItem>
                  </div>
                </Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.div`
  .menuButton {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const TrainNabar = () => {
  const { projectUid } = useParams();
  const { activeProject } = useProject();

  const TRAIN_LINKS = [
    {
      link: `/projects/${projectUid}/train/files`,
      label: "Files",
    },
    // activeProject?.purpose === Purpose.fineTuning
    //   ? {
    //       link: `/projects/${projectUid}/train/playground`,
    //       label: "Playground",
    //     }
    //   : null,
    activeProject?.purpose === Purpose.fineTuning
      ? {
          link: `/projects/${projectUid}/train/fine-tunes`,
          label: "Fine tunes",
        }
      : null,
    activeProject?.purpose === Purpose.chatbot
      ? {
          link: `/projects/${projectUid}/train/sitemap`,
          label: "Sitemap",
        }
      : null,
    activeProject?.purpose !== Purpose.fineTuning
      ? {
          link: `/projects/${projectUid}/train/pages`,
          label: "Pages",
        }
      : null,
  ];
  return (
    <div className="bg-white shadow-sm border-b border-solid border-gray-200  flex justify-center gap-4 z-10 items-center" style={{ height: THEME.secondaryNavbarHeight, top: THEME.navbarHeight }}>
      {TRAIN_LINKS.map((i) => {
        if (!i) return null;
        return (
          <NavLink key={i.label} to={i.link} className={({ isActive }) => `${isActive ? "text-black" : "text-black/40"} font-medium `}>
            {i.label}
          </NavLink>
        );
      })}
    </div>
  );
};
