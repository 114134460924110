import { TextareaAutosize } from "@mui/base";
import { Alert, Checkbox, Chip, ChipDelete, CircularProgress, IconButton, Input, LinearProgress, ListItemDecorator, Option, Select, Slider, Typography } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { playgroundApi } from "network/api/project";
import { useEffect, useRef, useState } from "react";
import { IoClose, IoSend } from "react-icons/io5";
import { ModelItemD } from "types/model";
import MessageList from "./MessageList";
import { Mode, PlaygroundMessageItemD } from "types/playground";
import toast from "react-hot-toast";
import { tv } from "tailwind-variants";
import { BsFileEarmarkCheckFill, BsFillChatSquareTextFill } from "react-icons/bs";
import { useProject } from "context/ProjectContext";
import { THEME } from "utils/constants/ui";
import ModelSelect from "components/ModelSelect";
import ChatList, { ChatItemD } from "./ChatList";
import { ApiResType } from "types/enum";
import { log, logErr } from "utils/helpers";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<any>();
  useEffect(() =>
    elementRef.current.scrollIntoView({
      behavior: "smooth",
    })
  );
  return <div ref={elementRef} />;
};

export type MessageItemD = PlaygroundMessageItemD & {
  sent?: boolean;
  text?: string;
};

export default function FineTunePg() {
  const { token } = useAuth();
  const { projectUid } = useProject();

  //General
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(true);

  //Response Lists
  const [messages, setMessages] = useState<MessageItemD[]>([]);
  const [chatModeItems, setChatModeItems] = useState<ChatItemD[]>([]);

  //SETTINGS
  const [mode, setMode] = useState(Mode.complete);
  const [model, setModel] = useState<ModelItemD | null>(null);
  const [temprature, setTemprature] = useState(0.5);
  const [maxLength, setMaxLength] = useState(10);
  const [maxTokens, setMaxTokens] = useState<any>(10); // n value
  const [endSquences, setEndSquences] = useState<string[]>([]);
  const [topP, setTopP] = useState(1);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [bestOf, setBestOf] = useState(1);

  const [injectStartEnable, setInjectStartEnable] = useState(false);
  const [injectStart, setInjectStart] = useState("");

  const [injectRestart, setInjectRestart] = useState("");
  const [injectRestartEnable, setInjectRestartEnable] = useState(false);

  /**
   * CHAT MODE
   */

  const onNew = () => {
    setChatModeItems((s) => [
      ...s,
      {
        id: Date.now(),
        text: "",
        type: "user",
      },
    ]);
  };

  const onToggle = (id: any) => {
    setChatModeItems((s) => {
      return s.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            type: i.type === "user" ? "assistant" : i.type === "assistant" ? "system" : "user",
          };
        } else {
          return i;
        }
      });
    });
  };

  const onRemove = (id: any) => {
    setChatModeItems((s) => s.filter((i) => i.id !== id));
  };

  const onTextChange = ({ text, id }: { text: string; id: any }) => {
    setChatModeItems((s) => {
      return s.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            text: text,
          };
        } else {
          return i;
        }
      });
    });
  };

  /**
   * CHAT MODE ENDS
   */

  /**
   * MODE CHANGE MANGE <<<<<<<<
   */

  useEffect(() => {}, [mode]);

  /**
   * MODE CHANGE ENDS >>>>>>>
   */

  const onSend = async () => {
    try {
      if (loadingModel) {
        toast("Wait for models to load!", {
          icon: "🙏",
        });
        return;
      }
      if (!model?.id) {
        return;
      }

      setText("");
      setError("");

      if (mode === Mode.complete) {
        setMessages((s): any => [
          ...s,
          {
            id: Date.now(),
            text: text,
            sent: true,
          },
        ]);
      }

      setLoading(true);

      let chatPayload: any = "";

      if (mode === Mode.chat && text.trim()) {
        setChatModeItems((s) => [
          ...s,
          {
            id: new Date().getTime(),
            text,
            type: "user",
          },
        ]);
        if (chatModeItems.length > 0) {
          chatPayload = [
            ...chatModeItems.map((i) => {
              return {
                role: i.type,
                content: i.text,
              };
            }),
            { role: "user", content: text },
          ];
        } else {
          chatPayload = [{ role: "user", content: text }];
        }
      } else {
        chatPayload = "";
      }

      const res = await playgroundApi({
        token,
        project_uid: projectUid,
        prompt: mode === Mode.complete ? text.trim() : "",
        model: model.id,
        mode: mode,
        messages: JSON.stringify(chatPayload),

        //OPTIONALS
        max_tokens: maxTokens.toString(),
        best_of: bestOf.toString(),
        frequency_penalty: frequencyPenalty.toString(),
        n: maxTokens.toString(),
        presence_penalty: presencePenalty.toString(),
        temperature: temprature.toString(),
        top_p: topP.toString(),
        inject_restart_text: injectRestartEnable ? injectRestart : "",
      });

      setLoading(false);

      if (res.type === ApiResType.SUCCESS) {
        if (mode === Mode.complete) {
          setMessages((s) => [...s, res.data]);
        } else {
          if (res.data.choices?.length > 0) {
            const msg = res.data?.choices[0]?.message;
            setChatModeItems((s) => [
              ...s,
              {
                id: res.data.choices[0].id,
                text: msg.content,
                type: msg.role,
              },
            ]);
          }
        }
      } else {
        setError(res.message);
      }
    } catch (err) {
      setLoading(false);
      setError("Something went wrong");
      logErr("Err", err);
    }
  };

  return (
    <div className="flex-1">
      <div className="box-border flex overflow-hidden">
        <div
          className="left relative flex flex-1 flex-col overflow-hidden"
          style={{
            height: `calc(100vh - ${THEME.navbarHeight}px)`,
          }}
        >
          <div className=" flex-1 flex-col  overflow-auto">
            <div className="flex-1">{mode === Mode.complete ? <MessageList list={messages} /> : <ChatList {...{ onNew, onTextChange, onRemove, onToggle, list: chatModeItems }} />}</div>

            <div className="flex h-44 w-full "></div>

            <AlwaysScrollToBottom />
          </div>

          {/* ACTIONBAR  */}
          <div
            className="absolute bottom-0 flex w-full flex-col gap-1"
            style={{
              background: `linear-gradient(360deg, rgba(255,255,255,1) 71%, rgba(255,255,255,0.7441570378151261) 90%, rgba(255,255,255,0) 100%)`,
            }}
          >
            <div className=" py-2 px-2 pt-6">
              {error && (
                <Alert variant="soft" size="sm" sx={{ mb: 1 }} color="danger">
                  {error}
                </Alert>
              )}
              {loading && <LinearProgress sx={{ borderRadius: 0 }} />}

              <div className="relative flex  items-start self-stretch">
                <TextareaAutosize
                  disabled={loading}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      onSend();
                    }
                  }}
                  maxRows={4}
                  minRows={4}
                  autoFocus
                  placeholder="Type here..."
                  className="w-full  resize-none rounded-lg border-solid border-gray-200 border-r-gray-200 bg-gray-100 py-3 px-3 text-base shadow-md outline-none transition-all  active:border-gray-200"
                  style={{ fontFamily: "inherit" }}
                />
                <div
                  className="group absolute right-3 mt-2 flex aspect-square h-12 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-blue-500 text-white/90 text-gray-500 transition-all hover:bg-blue-600 hover:text-white"
                  onClick={onSend}
                >
                  <div className="group-hover:animate-slideRight">
                    <IoSend />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `calc(100vh - ${THEME.navbarHeight}px)`,
          }}
          className="right box-border w-[280px] overflow-y-auto border-l border-r-0 border-b-0 border-t-0 border-solid border-gray-300 px-3 py-4 pb-16"
        >
          <div className="mb-4">
            <Typography fontWeight={"lg"}>Settings</Typography>
          </div>

          <div className={itemsV()}>
            {/* MODE  */}
            <div className={itemV()}>
              <div className={labelV()}>Mode</div>
              <Select
                size="sm"
                value={mode}
                onChange={(_, val) => {
                  if (val) {
                    setMode(val);
                  }
                }}
                slotProps={{
                  listbox: {
                    sx: {
                      "--List-decorator-size": "24px",
                    },
                  },
                }}
                sx={{
                  "--List-decorator-size": "24px",
                }}
              >
                <Option value={Mode.complete}>
                  <ListItemDecorator>
                    <BsFileEarmarkCheckFill />
                  </ListItemDecorator>
                  Complete
                </Option>
                <Option value={Mode.chat}>
                  <ListItemDecorator>
                    <BsFillChatSquareTextFill />
                  </ListItemDecorator>
                  Chat
                </Option>
                {/* <Option value="3">
                  <ListItemDecorator>
                    <RiMenuAddLine />
                  </ListItemDecorator>
                  Insert
                </Option>
                <Option value="4">
                  <ListItemDecorator>
                    <AiTwotoneEdit />
                  </ListItemDecorator>
                  Edit
                </Option> */}
              </Select>
            </div>

            {/* CHOOSE MODEL  */}
            <div className={itemV()}>
              <div className="flex justify-between">
                <div className={labelV()}>Choose Model</div>

                {loadingModel && <CircularProgress size="sm" sx={{ p: 1 }} />}
              </div>

              <ModelSelect
                mode={mode}
                autoSet={true}
                value={model}
                onLoadStart={() => {
                  setLoadingModel(true);
                }}
                onChange={(val: any) => {
                  setLoadingModel(false);
                  setModel(val);
                }}
              />
            </div>

            {/* TEMPRATURE  */}
            <div className={itemV({ class: "group" })}>
              <div className="flex items-center justify-between">
                <div className={labelV()}>Temprature</div>
                <input
                  className={labelInputV()}
                  value={temprature}
                  onChange={(e) => {
                    setTemprature(Number(e.target.value));
                  }}
                  type={"number"}
                />
              </div>
              <div className="px-1">
                <Slider
                  min={0.1}
                  max={3}
                  step={0.1}
                  value={temprature}
                  onChange={(_, e) => {
                    if (typeof e === "number") {
                      setTemprature(e);
                    }
                  }}
                />
              </div>
            </div>

            {/* MAXIMUM LENGTH  */}
            <div className={itemV({ class: "group" })}>
              <div className="flex items-center justify-between">
                <div className={labelV()}>Maximum length</div>
                <input value={maxLength} onChange={(e) => setMaxLength(Number(e.target.value))} className={labelInputV()} type={"number"} />
              </div>
              <div className="px-1">
                <Slider
                  min={1}
                  max={1000}
                  step={1}
                  value={maxLength}
                  onChange={(_, e) => {
                    if (typeof e === "number") {
                      setMaxLength(e);
                    }
                  }}
                />
              </div>
            </div>

            {/* MAX TOKENS  */}
            <div className={itemV()}>
              <div className={labelV()}>Max tokens</div>
              <Input size="sm" type="number" value={maxTokens} onChange={(e) => setMaxTokens(e.target.value)} />
            </div>

            {/* STOP SEQUENCES  */}
            {mode === Mode.complete && (
              <div className={itemV({ class: "group" })}>
                <div className={labelV({ class: "m-0" })}>Stop sequences</div>
                <p className={labelSmallV()}>Enter sequence and press Tab</p>
                <div className="flex items-start gap-[4px]">
                  <div className=" flex max-h-[160px] flex-1 flex-wrap gap-1 overflow-auto rounded-lg border border-solid border-gray-200 p-2 transition-all group-hover:border-gray-400 ">
                    <div className="flex flex-wrap gap-1">
                      {endSquences.map((i) => {
                        return (
                          <Chip color="primary" variant="soft" endDecorator={<ChipDelete onDelete={() => setEndSquences((s) => s.filter((i2) => i2 !== i))} />}>
                            {i}
                          </Chip>
                        );
                      })}
                    </div>

                    <input
                      className="border-none text-sm outline-none "
                      placeholder="Add end sequence"
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          setEndSquences((s) => [...s, e.target.value]);
                          e.target.value = "";
                        }

                        if (e.key === "Backspace" && !e.target.value) {
                          setEndSquences((s) => {
                            const d = [...s];
                            d.pop();
                            return d;
                          });
                        }
                      }}
                    />
                  </div>

                  <IconButton size="sm" onClick={() => setEndSquences([])} color="danger" sx={{ borderRadius: 120 }}>
                    <IoClose />
                  </IconButton>
                </div>
              </div>
            )}

            {/* TOP P  */}
            <div className={itemV({ class: "group" })}>
              <div className="flex items-center justify-between">
                <div className={labelV()}>Top P</div>
                <input value={topP} onChange={(e) => setTopP(Number(e.target.value))} className={labelInputV()} type={"number"} />
              </div>
              <div className="px-1">
                <Slider
                  min={1}
                  max={1000}
                  step={1}
                  value={topP}
                  onChange={(_, e) => {
                    if (typeof e === "number") {
                      setTopP(e);
                    }
                  }}
                />
              </div>
            </div>

            {/* Frequency Penalty */}
            <div className={itemV({ class: "group" })}>
              <div className="flex items-center justify-between">
                <div className={labelV()}>Frequency penalty</div>
                <input value={frequencyPenalty} onChange={(e) => setFrequencyPenalty(Number(e.target.value))} className={labelInputV()} type={"number"} />
              </div>
              <div className="px-1">
                <Slider
                  min={1}
                  max={1000}
                  step={1}
                  value={frequencyPenalty}
                  onChange={(_, e) => {
                    if (typeof e === "number") {
                      setFrequencyPenalty(e);
                    }
                  }}
                />
              </div>
            </div>

            {/* Presence Penalty */}
            <div className={itemV({ class: "group" })}>
              <div className="flex items-center justify-between">
                <div className={labelV()}>Presence penalty</div>
                <input value={presencePenalty} onChange={(e) => setPresencePenalty(Number(e.target.value))} className={labelInputV()} type={"number"} />
              </div>
              <div className="px-1">
                <Slider
                  min={1}
                  max={1000}
                  step={1}
                  value={presencePenalty}
                  onChange={(_, e) => {
                    if (typeof e === "number") {
                      setPresencePenalty(e);
                    }
                  }}
                />
              </div>
            </div>

            {/* Best of */}
            {mode === Mode.complete && (
              <div className={itemV({ class: "group" })}>
                <div className="flex items-center justify-between">
                  <div className={labelV()}>Best of</div>
                  <input value={bestOf} onChange={(e) => setBestOf(Number(e.target.value))} className={labelInputV()} type={"number"} />
                </div>
                <div className="px-1">
                  <Slider
                    min={1}
                    max={1000}
                    step={1}
                    value={bestOf}
                    onChange={(_, e) => {
                      if (typeof e === "number") {
                        setBestOf(e);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {/* Inject start text */}
            {mode === Mode.complete && (
              <div className={itemV()}>
                <div className={labelV()}>Inject start text</div>
                <Input startDecorator={<Checkbox checked={injectStartEnable} onChange={(e) => setInjectStartEnable((s) => !s)} />} size="sm" type="text" value={injectStart} onChange={(e) => setInjectStart(e.target.value)} />
              </div>
            )}

            {/* Inject restart text */}
            {mode === Mode.complete && (
              <div className={itemV()}>
                <div className={labelV()}>Inject restart text</div>
                <Input startDecorator={<Checkbox checked={injectRestartEnable} onChange={(e) => setInjectRestartEnable((s) => !s)} />} size="sm" type="text" value={injectRestart} onChange={(e) => setInjectRestart(e.target.value)} />
              </div>
            )}

            {/* Show probabilities */}
            {/* <div className={itemV()}>
              <div className={labelV()}>Show probabilities</div>
              <Select size="sm" placeholder="Choose one…" defaultValue={"Off"}>
                {["Off", "Most likely", "Least likely", "Full specturm"].map((i) => {
                  return <Option value={i}>{i}</Option>;
                })}
              </Select>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const labelInputV = tv({
  base: "border-transparent border active:border-blue-500 border-solid rounded-md outline-none text-sm p-1 w-10 group-hover:border-gray-400",
});
const labelV = tv({
  base: "text-sm mb-1 font-semibold text-gray-600",
});
const labelSmallV = tv({
  base: "text-xs mb-1  text-gray-400",
});
const itemsV = tv({
  base: "items flex flex-col gap-4",
});
const itemV = tv({
  base: "",
});
