import {  Modal, ModalClose, Sheet } from "@mui/joy";  
import { ProjectItemD } from "types/project";  
import { tv } from "tailwind-variants"; 
import ProjectForm from "./ProjectForm";

export default function ProjectCreateModal({ open, onCreate, onClose }: { open: boolean; onClose: () => any; onCreate: (d: ProjectItemD) => any }) {

  return (
    <Modal
      aria-labelledby="close-modal-title"
      open={open}
      onClose={() => {
        onClose(); 
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          minWidth: 300,
          borderRadius: "md",
          p: 3,
          maxWidth: "90%",
          width: 600,
          position: "relative",
          zIndex: 10,
          overflowY: 'auto'
        }}
      >
        <ModalClose variant="outlined" />  
        <ProjectForm onCreate={onCreate} onClose={onClose} />
      </Sheet>
    </Modal>
  );
}

export const card = tv({
  base: "border duration-300 rounded-lg p-5 w-1/2 min-h-[120px] flex flex-col items-center justify-between gap-2",
  variants: {
    active: {
      0: "",
      1: "bg-[rgba(9,107,222,0.03)] border-[#096bde] text-[#096bde]"
    },
    disabled: {
      0: "hover:bg-[rgba(9,107,222,0.03)] hover:border-[#096bde] hover:text-[#096bde] cursor-pointer",
      1: ""
    }
  },
  defaultVariants: {disabled: 1}
});

export type PurposeD = 'chatbot' | 'qa_expert'  | 'fine_tuning'  | 'content_writer'  | 'personal_assistant'  | 'customer_support_expert' | "";

export type ProjectInfoD = {projectUid: string, widgetUid: string};