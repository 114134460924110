import { post } from "network";

export const getAllModelsApi = async ({ token, ...raw }: { token: string; limit: number; page: number; search: string; project_uid: string }) => {
  return post({
    route: "/api/v1/basictuning/getAllModels",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
