import { post } from "network";
import { PLAN_KEY } from "types/subscription";

export const createSubscriptionApi = async ({ token, ...raw }: { token: string; id: string; plan: PLAN_KEY }) => {
  return post({
    route: "/api/v1/createSubscription",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const updateSubscriptionApi = async ({ token, ...raw }: { token: string; id: string; plan: PLAN_KEY }) => {
  return post({
    route: "/api/v1/updateSubscription",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getActivePlanDetailApi = async ({ token, ...raw }: { token: string; id: string; type: "project" | "organization" }) => {
  return post({
    route: "/api/v1/getActivePlanDetail",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const cancelSubscriptionApi = async ({ token, ...raw }: { token: string; subscription_id: string }) => {
  return post({
    route: "/api/v1/cancelSubscription",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
