import { TextareaAutosize } from "@mui/base";
import { Alert, LinearProgress } from "@mui/joy";
import { useAuth } from "context/AuthContext";
import { advancePlaygroundApi } from "network/api/project";
import React, { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import MessageList from "./MessageList";
import { Mode, PlaygroundMessageItemD } from "types/playground";
import { useProject } from "context/ProjectContext";
import { THEME } from "utils/constants/ui";
import ChatList, { ChatItemD } from "./ChatList";
import { ApiResType } from "types/enum";
import { log, logErr } from "utils/helpers";

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<any>();
  useEffect(() =>
    elementRef.current.scrollIntoView({
      behavior: "smooth",
    })
  );
  return <div ref={elementRef} />;
};

export type MessageItemD = PlaygroundMessageItemD & {
  sent?: boolean;
  text?: string;
};

export default function OtherPurposePg() {
  const { token } = useAuth();
  const { projectUid, activeProject } = useProject();

  //General
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  //Response Lists
  const [messages, setMessages] = useState<MessageItemD[]>([]);
  const [chatModeItems, setChatModeItems] = useState<ChatItemD[]>([]);

  //SETTINGS
  const [mode, setMode] = useState(Mode.chat);

  /**
   * CHAT MODE
   */

  const onNew = () => {
    setChatModeItems((s) => [
      ...s,
      {
        id: Date.now(),
        text: "",
        type: "user",
      },
    ]);
  };

  const onToggle = (id: any) => {
    setChatModeItems((s) => {
      return s.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            type: i.type === "assistant" ? "user" : "assistant",
          };
        } else {
          return i;
        }
      });
    });
  };

  const onRemove = (id: any) => {
    setChatModeItems((s) => s.filter((i) => i.id !== id));
  };

  const onTextChange = ({ text, id }: { text: string; id: any }) => {
    setChatModeItems((s) => {
      return s.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            text: text,
          };
        } else {
          return i;
        }
      });
    });
  };

  const onSend = async () => {
    try {
      setLoading(true);

      const res = await advancePlaygroundApi({
        token,
        project_uid: projectUid,
        query: text,
        model_name: "text-davinci-003",
      });

      setLoading(false);

      if (res.type === ApiResType.SUCCESS) {
        if (mode === Mode.complete) {
          setMessages((s) => [...s, res.data]);
        } else {
          if (res.data.choices?.length > 0) {
            const msg = res.data?.choices[0]?.message;
            setChatModeItems((s) => [
              ...s,
              {
                id: res.data.choices[0].id,
                text: msg.content,
                type: msg.role,
              },
            ]);
          }
        }
      } else {
        setError(res.message);
      }
    } catch (err) {
      setLoading(false);
      setError("Something went wrong");
      logErr("Err", err);
    }
  };

  return (
    <div className="flex-1">
      <div className="box-border flex overflow-hidden">
        <div
          className="left relative flex flex-1 flex-col overflow-hidden"
          style={{
            height: `calc(100vh - ${THEME.navbarHeight + THEME.secondaryNavbarHeight}px)`,
          }}
        >
          <div className=" flex-1 flex-col  overflow-auto">
            <div className="flex-1">{mode === Mode.complete ? <MessageList list={messages} /> : <ChatList {...{ onNew, onTextChange, onRemove, onToggle, list: chatModeItems }} />}</div>

            <div className="flex h-44 w-full "></div>

            <AlwaysScrollToBottom />
          </div>

          {/* ACTIONBAR  */}
          <div
            className="absolute bottom-0 flex w-full flex-col gap-1"
            style={{
              background: `linear-gradient(360deg, rgba(255,255,255,1) 71%, rgba(255,255,255,0.7441570378151261) 90%, rgba(255,255,255,0) 100%)`,
            }}
          >
            <div className=" py-2 px-2 pt-6">
              {error && (
                <Alert variant="soft" size="sm" sx={{ mb: 1 }} color="danger">
                  {error}
                </Alert>
              )}
              {loading && <LinearProgress sx={{ borderRadius: 0 }} />}

              <div className="relative flex  items-start self-stretch">
                <TextareaAutosize
                  disabled={loading}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      onSend();
                    }
                  }}
                  maxRows={4}
                  minRows={4}
                  autoFocus
                  placeholder="Type here..."
                  className="w-full  resize-none rounded-lg border-solid border-gray-200 border-r-gray-200 bg-gray-100 py-3 px-3 text-base shadow-md outline-none transition-all  active:border-gray-200"
                  style={{ fontFamily: "inherit" }}
                />
                <div
                  className="group absolute right-3 mt-2 flex aspect-square h-12 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-blue-500 text-white/90 text-gray-500 transition-all hover:bg-blue-600 hover:text-white"
                  onClick={onSend}
                >
                  <div className="group-hover:animate-slideRight">
                    <IoSend />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
