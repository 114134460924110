import { appContent } from "components/variants/app";
import { useAuth } from "context/AuthContext";
import { getFinetuneDatasetApi } from "network/api/project/datasets";
import { useEffect, useState } from "react";
import { ApiResType } from "types/enum";
import Card from "./Card";
import { DatasetItemD } from "types/dataset";
import { Button, Input } from "@mui/joy";
import { useDebounce } from "use-debounce";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useProject } from "context/ProjectContext";
import { AiFillLock } from "react-icons/ai";
import { FaMagic } from "react-icons/fa";
import { upgradeToast } from "components/customToasts/UpgradeToast";

export default function Datasets() {
  const { token } = useAuth();
  const { isPro } = useProject();
  const navigate = useNavigate();

  const [datasets, setDatasets] = useState([] as DatasetItemD[]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [delaySearch] = useDebounce(search, 300);

  useEffect(() => {
    if (!token) return;

    const fetchDatasets = async () => {
      try {
        setLoading(true);
        const res = await getFinetuneDatasetApi({
          token,
          limit: 50,
          page: 1,
          ...(delaySearch ? { search: delaySearch } : {}),
        });
        if (res?.type === ApiResType.SUCCESS) {
          setDatasets(res.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchDatasets();
  }, [token, delaySearch]);

  return (
    <div className={appContent({ place: "train" })}>
      <header className="flex items-start gap-3">
        <div className="mr-auto">
          <h2 className="text-lg font-semibold text-black/70">Datasets</h2>
          <p className="text-sm text-black/70 mb-5">Curate your model's mastery with meticulously selected datasets</p>
        </div>

        <Input
          placeholder="Search..."
          value={search}
          size="sm"
          variant="soft"
          startDecorator={<HiOutlineMagnifyingGlass />}
          onChange={(e) => setSearch(e.target.value)}
          endDecorator={<>{search.length > 0 && <GrFormClose onClick={() => setSearch("")} className="cursor-pointer" />}</>}
        />

        <Button
          size="sm"
          onClick={() => {
            isPro
              ? navigate("create")
              : upgradeToast({
                  onUpgrade: () => navigate("/upgrade"),
                });
          }}
        >
          {isPro ? <FaMagic className="mr-1" /> : <AiFillLock />} <span className="ml-1">Generate with AI</span>
        </Button>
      </header>

      <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
        {loading ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="bg-gray-100 min-h-[150px] px-2 rounded-xl w-full animate-pulse" />
            ))}
          </>
        ) : (
          <>
            {datasets.map((dataset, index) => (
              <Card dataset={dataset} key={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
