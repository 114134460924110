import { MissingKeyOverlay } from "components/MissingKeyBox";
import Navbar from "components/Navbar";
import { useProject } from "context/ProjectContext";
import { Outlet, useLocation } from "react-router-dom";
import { THEME } from "utils/constants/ui";

export default function ProjectLayout() {
  const { activeProject } = useProject();
  const location = useLocation();

  return (
    <>
      <Navbar project />
      <div className="relative" style={{ height: `calc(100vh - ${THEME.navbarHeight}px)` }}>
        <Outlet />

        {!activeProject?.loading && !activeProject?.ProjectSetting?.openai_api_key && !location.pathname.includes("settings/openAiKey") && <MissingKeyOverlay />}
      </div>
    </>
  );
}
