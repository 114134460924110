import { CssVarsProvider } from "@mui/joy";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { router } from "router";

function App() {
  return (
    <div className="App">
      <div className="bg-red-500 text-center py-1 text-white">TuneUp is depreciated. We no longer maintain this project.</div>
      <Toaster />
      <CssVarsProvider
        defaultMode="light"
        //
        // the local storage key to use
        modeStorageKey="joyTheme"
        //
        // set as root provider
        // disableNestedContext
      >
        <RouterProvider router={router} />
      </CssVarsProvider>
    </div>
  );
}

export default App;
