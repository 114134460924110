import { post } from "network";
import { SortD } from "types";

export const getProjectMembersApi = async ({ token, ...raw }: { token: string; limit: number; page: number; search?: string; orderBy?: SortD; project_uid: string }) => {
  return post({
    route: "/api/v1/getProjectMembers",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const removeProjectMemberApi = async ({ token, ...raw }: { token: string; project_uid: any; member_id: any }) => {
  return post({
    route: "/api/v1/removeProjectMember",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const addProjectMemberByHashApi = async ({ token, ...raw }: { token: string; hash: string }) => {
  return post({
    route: "/api/v1/addProjectMemeberViaHash",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const inviteProjectMemberApi = async ({ token, ...raw }: { token: string; email: string; role?: string; project_uid: string }) => {
  return post({
    route: "/api/v1/inviteProjectMember",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
