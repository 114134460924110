import { post } from "network";

export const getFineTuneJobsApi = async ({ token, ...raw }: { token: string; limit: number; page: number; search: string; project_uid?: string }) => {
  return post({
    route: "/api/v1/basictuning/getFineTuneJobs",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getFineTuneJobDetailApi = async ({ token, ...raw }: { token: string; project_uid: string; id: string }) => {
  return post({
    route: "/api/v1/basictuning/getFineTuneJobDetail",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const getFineTuneJobEventsApi = async ({ token, ...raw }: { token: string; project_uid: string; fine_tune_id: string }) => {
  return post({
    route: "/api/v1/basictuning/getFineTuneJobEvents",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};

export const cancelFineTuneJobApi = async ({ token, raw }: { token: string; raw: any }) => {
  return post({
    route: "/api/v1/basictuning/cancelFineTuneJob",
    data: JSON.stringify(raw),
    config: {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
      },
    },
  });
};
