import { useEffect, useState } from "react";
import { Button, FormControl, FormLabel, Input, Typography, Alert } from "@mui/joy";
import { OrganizationItemD } from "types/org";
import { useAuth } from "context/AuthContext";
import { ProjectItemD } from "types/project";
import { getOrganisationApi } from "network/api/organization";
import { logErr } from "utils/helpers";
import { createProjectApi } from "network/api/project";
import { toast } from "react-hot-toast";
import { tv } from "tailwind-variants";
import { Purpose } from "types/enum";
import { useProject } from "context/ProjectContext";

export default function ProjectForm({ onCreate, onClose }: { onCreate: (d: ProjectItemD) => any; onClose: () => void }) {
  const { organization } = useProject();
  const [creating, setCreating] = useState(false);
  const { token, clearMetaInfo, metaInfo } = useAuth();
  const [projName, setProjName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState({} as { type: string; message: string } | null);

  // FUNCTIONS
  const onSubmit = async (e: any) => {
    try {
      setCreating(true);
      const res = await createProjectApi({
        token,
        organization_id: organization?.id?.toString(),
        name: projName,
        type: "basic",
        purpose: Purpose.fineTuning,
        website_url: websiteUrl,
      });

      if (res.type === "RXSUCCESS") {
        toast.success("Project created successfully!", {
          position: "bottom-center",
        });
        onCreate({ ...res.data });
        onClose();
      } else if (res.type === "RXERROR") {
        setErrorMsg({ type: "apiErr", message: res?.message || "Unexpected error occurred" });
      }
      clearMetaInfo();
      setCreating(false);
    } catch (err: any) {
      setCreating(false);
      setErrorMsg({ type: "apiErr", message: err?.message || "Unexpected error occurred" });
      logErr("Err", err);
    }
  };

  useEffect(() => {
    if (metaInfo) {
      setWebsiteUrl(metaInfo.url);
    }
  }, [metaInfo]);

  return (
    <div className="max-w-[600px] mx-auto my-5">
      <Typography level="title-lg">Add new project</Typography>

      <div className="mt-5">
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <FormControl required>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Name of your project"
              name="name"
              value={projName}
              onChange={(e) => setProjName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </FormControl>

          <Button loading={creating} type="submit">
            Create Project
          </Button>
          {errorMsg?.type === "apiErr" && (
            <Alert color="danger" variant="soft" size="sm" className="mb-3">
              {errorMsg?.message}
            </Alert>
          )}
        </form>
      </div>
    </div>
  );
}

export const card = tv({
  base: "border duration-300 rounded-lg p-5 w-1/2 flex flex-col items-center justify-start gap-2 min-h-[145px]",
  variants: {
    active: {
      0: "",
      1: "bg-[rgba(9,107,222,0.03)] border-[#096bde] text-[#096bde]",
    },
    disabled: {
      0: "hover:bg-[rgba(9,107,222,0.03)] hover:border-[#096bde] hover:text-[#096bde] cursor-pointer",
      1: "",
    },
  },
  defaultVariants: { disabled: 1, active: 0 },
});
