import { Menu, MenuItem } from "@mui/joy";
import { appContent } from "components/variants/app";  
import { useState } from "react";
import { IoFilter } from "react-icons/io5";
import { RiArrowDownSLine } from "react-icons/ri";
import Overview from "./Overview";
import Table from "./Table";

export default function ProjectDashboard() { 
  // const [anchorEl, setAnchorEl] = useState<any>(null);
  // const menuOpen = Boolean(anchorEl); 

    return (
      <div
        style={{background: '#fbfbfb', minHeight: '100vh'}}>
      <div className={appContent({ place: "console" })}>
      <Overview />

      <div className="flex items-center justify-between mb-5">
        <h2 className="text-xl font-semibold text-black/70">Sessions</h2>
        <div>
            {/* <div className="flex items-center gap-2 cursor-pointer text-black/70" onClick={(e) => setAnchorEl(e.currentTarget)}> 
                <IoFilter />
                <span>Filter</span> 
                <RiArrowDownSLine className={`${menuOpen ? "rotate-180 duration-200" : ""}`} />
            </div>  */}
            {/* <Menu 
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(null)}
                sx={{
                minWidth: 180,
                }}
            > 
                <MenuItem
                    sx={{":focus-visible": {outline: "none"}}}
                    color="neutral"
                    onClick={() => {}}
                >
                Sort by date
                </MenuItem>
                <MenuItem
                    sx={{":focus-visible": {outline: "none"}}}
                    color="neutral"
                    onClick={() => {}}
                >
                Sort by status
                </MenuItem>
            </Menu> */}
        </div>
      </div>
      <Table />
    </div>
      </div>
  );
}

// import { ReactComponent as Fig } from "assets/svg/dashboard-soon-2.svg";
// import { THEME } from "utils/constants/ui";

// <div className="flex items-center justify-center" style={{ minHeight: `calc(100vh - ${THEME.navbarHeight}px)` }}>
//   <div>
//     <Fig style={{ height: 360, width: "auto" }} className="" />
//     <div className="font-medium text-2xl text-center animate-pulse text-gray-500 ">Coming soon...</div>
//   </div>
// </div>
