import { post } from "network";
  
// SITEMAP
export const createProjectSitemapApi = async ({ token, ...raw }: { token: string,  project_uid: string, site_map_url: string } ) => {
    return post({
        route: "/api/v1/createProjectSiteMap",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

export const getProjectSiteMapApi = async ({ token, ...raw }: { token: string,  project_uid: string } ) => {
    return post({
        route: "/api/v1/getProjectSiteMap",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

export const deleteProjectSiteMapApi = async ({ token, ...raw }: { token: string,  project_uid: string, id: string } ) => {
    return post({
        route: "/api/v1/deleteProjectSiteMap",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

export const getUrlsFromSitemapApi = async ({ token, ...raw }: { token: string,  project_uid: string } ) => {
    return post({
        route: "/api/v1/getUrlsFromSitemap",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

// PROJECT URL
export const createProjectUrlApi = async ({ token, ...raw }: { token: string,  project_uid: string, site_map_id?: string, url: string } ) => {
    return post({
        route: "/api/v1/createProjectUrl",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

export const getProjectUrlApi = async ({ token, ...raw }: { token: string,  project_uid: string } ) => {
    return post({
        route: "/api/v1/getProjectUrl",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};

export const deleteProjectUrlApi = async ({ token, ...raw }: { token: string,  project_uid: string, id: string} ) => {
    return post({
        route: "/api/v1/deleteProjectUrl",
        data: JSON.stringify(raw),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
        },
        },
    });
};