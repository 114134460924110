import { post } from "network";

export const getDashboardStatsApi = async ({ token, data }: {token: string, data: {projectUid: string}}) => {
    // const raw = new URLSearchParams();
    // raw.append("project_uid", data.projectUid); 

    return post({
        route: "/api/v1/getStats",
        data: JSON.stringify({project_uid: data.projectUid}),
        config: {
        headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
            // "Content-type": "application/x-www-form-urlencoded",
        },
        },
    });
};

export const getProjectSessionsApi = async ({ raw, token }: {token:string, raw: {project_uid: string, status: any}}) => {
    return post({
        route: "/api/v1/getProjectSession",
        data: JSON.stringify(raw),
        config: {
        headers: { 
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
        },
        },
    });
};

export const getSessionMessagesBySessionIdApi = async ({ raw, token }: {token: string, raw: {session_uid: string, page?: number, limit?: number, orderBy?: 'asc' | 'desc'}}) => {
    return post({
        route: "/api/v1/getSessionMessagesBySessionId",
        data: JSON.stringify(raw),
        config: {
        headers: { 
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
        },
        },
    });
};