import { useAuth } from "context/AuthContext";
import { useProject } from "context/ProjectContext";
import { createSubscriptionApi } from "network/api/project/subscription";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsCheckCircleFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { IoIosCloseCircle } from "react-icons/io";
import { tv } from "tailwind-variants";
import { ApiResType } from "types/enum";
import { PLAN_KEY } from "types/subscription";
import SubscriptionInfo from "./SubscriptionInfo";

const Check = <BsCheckCircleFill size={20} className="text-green-700" />;
const Cross = <IoIosCloseCircle size={24} className="text-red-500" />;

const features = [
  {
    name: "Users",
    description: "Add your team or anyone else involved in your work.",
    plan1: "1",
    plan2: "Unlimited",
  },
  {
    name: "No. of projects",
    plan1: "Unlimited",
    plan2: "Unlimited",
  },
  {
    name: "Plaground",
    plan1: Check,
    plan2: Check,
  },
  {
    name: "AI Dataset Builder",
    description: "Add your team or anyone else involved in your work.",
    plan1: Cross,
    plan2: Check,
  },
  {
    name: "Dataset Library access",
    description: "Add your team or anyone else involved in your work.",
    plan1: Cross,
    plan2: Check,
  },
  {
    name: "Priority Support",
    plan1: Cross,
    plan2: Check,
  },
];

export default function Pricing() {
  const { token } = useAuth();
  const { organization, isPro } = useProject();
  const [loading, setLoading] = useState(false);

  const onUpgrade = async () => {
    if (!token || !organization?.id) return;
    try {
      setLoading(true);
      const res = await createSubscriptionApi({ token, id: organization?.id?.toString(), plan: PLAN_KEY.tuneup_professional_yearly });

      if (res?.type === ApiResType.SUCCESS) {
        if (window !== undefined && res?.data.url) {
          window.location.href = res?.data.url;
        }
        if (res?.message) {
          toast.success(res?.message);
        }
      }

      if (res?.type === ApiResType.ERROR) {
        toast.error(res?.message || "Something went wrong");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
      console.error("Error", error);
    }
  };

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col overflow-hidden">
          <div className="grid grid-cols-3 font-semibold">
            <div className={cellHeader()}>
              <h4 className="text-base md:text-lg">Features</h4>
            </div>
            <div className={cellHeader()}>
              <h5 className="text-xs md:text-sm opacity-60 mb-3">HOBBY</h5>
              <h3 className="text-lg md:text-2xl font-semibold">FREE</h3>
            </div>
            <div className={cellHeader({ class: "bg-blue-500 text-white rounded-t-xl" })}>
              <h5 className="text-xs md:text-sm opacity-60 mb-3">PROFESSIONAL</h5>
              <h3 className="text-lg md:text-2xl ">
                <span className="font-bold">$49/Year</span> <span className="line-through opacity-60 text-base md:text-lg">$99</span>
              </h3>
            </div>
          </div>
          {features.map((feature, index) => (
            <div key={index} className="grid grid-cols-3 even:bg-gray-100 ">
              <div className={cellContent({ class: "justify-start" })}>
                <div>
                  <h4 className="font-medium mb-[6px]">{feature.name}</h4>
                  {feature.description && <p className="text-sm text-zinc-700">{feature.description}</p>}
                </div>
              </div>
              <div className={cellContent()}>{feature.plan1}</div>

              <div className={cellContent({ class: "border-x border-x-blue-600" })}>{feature.plan2}</div>
            </div>
          ))}
        </div>

        {isPro ? (
          <SubscriptionInfo />
        ) : (
          <div className="flex justify-center">
            <button
              onClick={onUpgrade}
              className="bg-blue-600 font-semibold hover:ring-4 hover:scale-[1.05] hover:shadow-sm hover:ring-blue-200 hover:bg-blue-700 transition-all text-white mt-6 relative overflow-hidden rounded-xl px-4 py-3"
            >
              {loading && (
                <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-blue-600">
                  <CgSpinner className="animate-spin text-xl" />
                </div>
              )}
              <span>🚀 Upgrade to Professional</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const cellHeader = tv({
  base: "flex items-center justify-center p-2 py-4 flex-col",
});
const cellContent = tv({
  base: "flex justify-center items-center p-3 px-2",
});
