import { useAuth } from "context/AuthContext";
import { getProjectsApi } from "network/api/project";
import { getOrganisationApi } from "network/api/organization";
import { useEffect, useState } from "react";
import { SortD } from "types";
import { useDebounce } from "use-debounce";
import { getFilesApi, getProjectFilesApi } from "network/api/project/file";
import { getFineTunesApi } from "network/api/project/fineTune";
import { logErr } from "utils/helpers";
import { getFineTuneJobsApi } from "network/api/project/fineTuneJobs";

type ApiType = "org" | "member" | "project" | "projectFiles" | "models" | "fineTuneFiles" | "fineTuneJobs";

export type ListingApiKeys<Item> = {
  data: Item[];
  total: number;
  apiError: string;
  loading: boolean;
  setData: React.Dispatch<React.SetStateAction<Item[]>>;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
};

export function useListingApi({ type, sort, search, page, limit, project_uid }: { type: ApiType; search: string; sort: SortD; page: number; limit: number; project_uid?: string }): ListingApiKeys<any> {
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [delaySearch] = useDebounce(search, 300);
  const { token } = useAuth();

  useEffect(() => {
    const fe = async () => {
      try {
        let res: any;

        const dat = {
          limit,
          page,
          search: delaySearch,
          token,
          orderBy: sort,
        };
        setLoading(true);
        switch (type) {
          case "project":
            res = await getProjectsApi(dat);
            break;
          case "org":
            res = await getOrganisationApi(dat);
            break;
          case "projectFiles":
            res = await getProjectFilesApi({ ...dat, project_uid });
            break;
          case "fineTuneFiles":
            res = await getFilesApi({ ...dat, project_uid });
            break;
          case "models":
            res = await getFineTunesApi({ ...dat, project_uid });
            break;
          case "fineTuneJobs":
            res = await getFineTuneJobsApi({ ...dat, project_uid });
            break;
          default:
        }

        setLoading(false);

        if (res.type === "RXSUCCESS") {
          setData(res.data);
          setTotal(res.total);
        } else {
          setData([]);
          setTotal(0);
          setApiError(res.message);
        }
      } catch (err) {
        setLoading(false);
        logErr("ERR", err);
      }
    };

    if (token) {
      if ((type === "projectFiles" || type === "models") && project_uid) {
        fe();
      } else {
        fe();
      }
    }
  }, [page, limit, delaySearch, token, sort, project_uid, type]);

  return {
    apiError,
    loading,
    data,
    total,
    setTotal,
    setData,
  };
}
