import EmailVerifyAlert from "components/EmailVerifyAlert";
import Navbar from "components/Navbar";
import React from "react";
import { Outlet } from "react-router-dom";

export default function RootLayout() {
  return (
    <div>
      <Navbar />
      <EmailVerifyAlert />
      <Outlet />
    </div>
  );
}
