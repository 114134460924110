import { IconButton, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import React from "react";
import { HiArrowLeft } from "react-icons/hi";
import { IoArrowBack } from "react-icons/io5";

export default function BackHeader({ title, desc = "", right }: { title: string; desc?: string; right?: React.ReactNode }) {
  const navigate = useNavigate();

  return (
    <div className={`mb-4 flex ${desc ? "items-start" : "items-center"} gap-2`}>
      <IconButton
        color="neutral"
        variant="plain"
        onClick={() => {
          navigate(-1);
        }}
      >
        <HiArrowLeft />
      </IconButton>

      <div className="flex-1">
        <Typography textColor={"neutral.800"} fontWeight={"lg"}>
          {title}
        </Typography>
        <Typography level="body-md" textColor={"neutral.400"}>
          {desc}
        </Typography>
      </div>

      {right && right}
    </div>
  );
}
